import {KnownRoomCategoryComforts} from "@skbkontur/hotel-data/roomCategory/RoomCategoryComforts";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import styles from "../RoomCategoryComforts.scss";
import {ROOM_CATEGORY_COMFORTS_GROUP_MAP, RoomCategoryComfortsGroupType} from "./RoomCategoryComfortsGroupContext";
import RoomCategoryComfortsIcon from "./RoomCategoryComfortsIcon";

interface IRoomCategoryComfortsItemProps {
    comfortType: KnownRoomCategoryComforts;
    groupType: RoomCategoryComfortsGroupType;
    hideIcon?: boolean;
}

const RoomCategoryComfortsItem = (props: IRoomCategoryComfortsItemProps) => {
    const {comfortType, hideIcon, groupType} = props;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    return (
        <div data-tid={`${comfortType}ComfortsItem`} className={styles.item}>
            {!hideIcon && (
                <div data-tid="Icon" className={styles.icon}>
                    <RoomCategoryComfortsIcon comfortType={comfortType} groupType={groupType}/>
                </div>
            )}
            <div data-tid="Name">
                {t(`comforts.${groupType}.${ROOM_CATEGORY_COMFORTS_GROUP_MAP[groupType][comfortType]}`)}
            </div>
        </div>
    );
};
RoomCategoryComfortsItem.displayName = "RoomCategoryComfortsItem";
export default RoomCategoryComfortsItem;
