import {StarIcon16Solid} from "@skbkontur/icons/StarIcon16Solid";
import {StarIcon16Light} from "@skbkontur/icons/StarIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type StarIconTypes = IconTypes.Solid16 | IconTypes.Light16;

interface IStarIconProps extends IconProps {
    type: StarIconTypes;
}

const StarIcon = ({type, ...iconProps}: IStarIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <StarIcon16Solid {...iconProps}/>;
        case IconTypes.Light16:
            return <StarIcon16Light {...iconProps}/>;
    }
};
StarIcon.displayName = "StarIcon";
export default StarIcon;
