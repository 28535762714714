import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import cn from "classnames";
import {StringUtils} from "@skbkontur/hotel-utils";
import KitchenIcon from "../../../icon/KitchenIcon";
import {IconTypes, StarIcon} from "../../../icon";
import {SvgIcon, SvgIconType} from "../../../svgIcon";
import EyeIcon from "../../../icon/EyeIcon";
import styles from "../RoomCategoryComforts.scss";
import {RoomCategoryComfortsGroupType} from "./RoomCategoryComfortsGroupContext";

interface IRoomCategoryComfortsGroupProps {
    type: RoomCategoryComfortsGroupType;
    title?: React.ReactNode;
    bigTitle?: boolean;
    withoutTitlePadding?: boolean;
}

const RoomCategoryComfortsGroup = (props: React.PropsWithChildren<IRoomCategoryComfortsGroupProps>) => {
    const {type, title, children, bigTitle, withoutTitlePadding} = props;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const getIcon = (): React.ReactNode => {
        switch (type) {
            case RoomCategoryComfortsGroupType.Kitchen:
                return <KitchenIcon type={IconTypes.Light16} data-tid="KitchenIcon"/>;

            case RoomCategoryComfortsGroupType.Bathroom:
                return <SvgIcon type={SvgIconType.Bathroom} data-tid="BathroomIcon"/>;

            case RoomCategoryComfortsGroupType.Unrecognized:
                return <StarIcon type={IconTypes.Light16} data-tid="UnrecognizedIcon"/>;

            case RoomCategoryComfortsGroupType.WindowView:
                return <EyeIcon type={IconTypes.Light16} data-tid="WindowViewIcon"/>;

            default:
                captureSentryError(`Unexpected icon type ${type}`, SentryErrorType.SwitchCase);
                return null;
        }
    };

    const showTitle = type !== RoomCategoryComfortsGroupType.General || !!title;

    return (
        <div data-tid={`${StringUtils.capitalizeFirstLetter(type)}Group`}>
            {showTitle && (
                <div
                    className={cn(styles.groupTitle, {
                        [styles.big]: bigTitle,
                        [styles.withoutTitlePadding]: withoutTitlePadding
                    })}
                >
                    <div className={styles.groupIcon}>
                        {getIcon()}
                    </div>
                    <div data-tid="Title">
                        {title || t(`comforts.groups.${type}`)}
                    </div>
                </div>
            )}
            <div className={styles.groupContent}>
                {children}
            </div>
        </div>
    );
};
RoomCategoryComfortsGroup.displayName = "RoomCategoryComfortsGroup";
export default RoomCategoryComfortsGroup;
