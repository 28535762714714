import {captureSentryError, SentryErrorType} from "@skbkontur/hotel-sentry";
import * as Sentry from "@sentry/react";
import {makePromiseCancellable, PromiseCancellable} from "../types/PromiseCancellable";
import {UrlBuilder} from "./UrlBuilder";

const fetchImpl = window.fetch;

export class FetcherError {
    public response: Response;
    public innerError: unknown;
    public message: string;

    constructor(message: string, response: Response, innerError?: unknown) {
        this.message = message;
        this.response = response;
        this.innerError = innerError;
    }
}

export interface IFetcherOptions<TResponse> {
    retryConfig?: IRetryConfig<TResponse>;
}

export interface IRetryConfig<TResponse> {
    retries: number;
    // в милисекундах
    delay: number;
    // по дефолту возвращает false
    needRetryOnSuccess?: (response: TResponse) => boolean;
    // по дефолту возвращает true
    needRetryOnError?: (error: FetcherError) => boolean;
}

// TODO Выделить в Common с Отельной версией

class Fetcher {
    private baseUrl = "";
    private errorHandler: (e: FetcherError) => Promise<never> = null;

    public setBaseUrl(url: string) {
        this.baseUrl = url;
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }

    public setErrorHandler(handler: (e: FetcherError) => Promise<never>) {
        this.errorHandler = handler;
    }

    private patchRequestInit(init?: RequestInit): RequestInit {
        init = init || {};
        return init;
    }

    private patchHeaders(headers: HeadersInit): HeadersInit {
        return headers;
    }

    private checkResponseStatus(response: Response): Response {
        if (response.status < 200 || response.status >= 300) {
            throw new FetcherError(response.statusText, response);
        }
        return response;
    }

    public async convert<T>(response: Response): Promise<T> {
        if (response.status === 204) {
            return;
        }

        const contentType = response.headers.get("content-type");

        if (!contentType) {
            if (response.status === 201) {
                return;
            }
            throw new FetcherError(`Response ${response.url} has no contentType`, response);
        }

        if (contentType.indexOf("application/json") !== -1)
            return response.json() as Promise<T>;

        if (contentType.indexOf("text/plain") !== -1)
            return response.text() as Promise<T>;

        throw new FetcherError(`Response ${response.url} has unsupported contentType ${contentType}`, response);
    }

    public fetch<T>(
        url: string,
        init?: RequestInit,
        options: IFetcherOptions<T> = {} as IFetcherOptions<T>
    ): Promise<T> {
        const {retryConfig} = options;

        const makeRequest = (): Promise<T> => fetchImpl(this.baseUrl + url, this.patchRequestInit(init))
            .then((r: Response) => this.checkResponseStatus(r))
            .then(async (r: Response) => {
                const response = await this.convert<T>(r);
                if (init) {
                    Sentry.addBreadcrumb({
                        message: this.baseUrl + url,
                        category: "request",
                        data: {request: init, response}
                    });
                }
                return response;
            });

        const request = retryConfig ? this.retry(makeRequest, retryConfig) : makeRequest();

        return request
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            .then(null, (e: any) => Promise.reject(this.getOrCreateError(e)))
            .then(null, (e: FetcherError) => this.errorHandler ? this.errorHandler(e) : Promise.reject(e));
    }

    public retry = <T>(
        apiCallFunction: () => Promise<T>,
        retryConfig: IRetryConfig<T>
    ): PromiseCancellable<T> => (
        makePromiseCancellable(
            async (isCancelled: () => boolean): Promise<T> => {
                const {delay, needRetryOnSuccess = () => false, needRetryOnError = () => true} = retryConfig;
                let {retries} = retryConfig;

                if (retries <= 0) {
                    retries = 1;
                    captureSentryError(
                        `retries should be positive, retries count: ${retries}`,
                        SentryErrorType.Fetcher
                    );
                }

                while (!isCancelled()) {
                    retries--;
                    try {
                        const response = await apiCallFunction();
                        if (!retries || !needRetryOnSuccess(response)) {
                            return response;
                        }
                    } catch (error) {
                        if (!retries || !(error instanceof FetcherError) || !needRetryOnError(error)) {
                            throw error;
                        }
                    } finally {
                        await this.wait(delay);
                    }
                }
            }
        )
    );

    private wait(delay: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, delay));
    }

    private getOrCreateError(e: string | Error | FetcherError): FetcherError {
        if (e instanceof FetcherError)
            return e;
        return new FetcherError(e && e.toString(), null, e);
    }

    public postJSON<T>(url: string, data: unknown, options?: IFetcherOptions<T>): Promise<T> {
        return this.fetch(url, {
            method: "POST",
            headers: this.patchHeaders({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify(data)
        }, options);
    }

    public patchJSON<T>(url: string, data: unknown, options?: IFetcherOptions<T>): Promise<T> {
        return this.fetch(url, {
            method: "PATCH",
            headers: this.patchHeaders({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify(data)
        }, options);
    }

    public patch<T>(url: string, options?: IFetcherOptions<T>): Promise<T> {
        return this.fetch(url, {
            method: "PATCH",
            headers: this.patchHeaders({})
        }, options);
    }

    public putJSON<T>(url: string, data: unknown, options?: IFetcherOptions<T>): Promise<T> {
        return this.fetch(url, {
            method: "PUT",
            headers: this.patchHeaders({
                "Content-Type": "application/json"
            }),
            body: JSON.stringify(data)
        }, options);
    }

    public del(url: string, options?: IFetcherOptions<void>): Promise<void> {
        return this.fetch(url, {
            method: "DELETE",
            headers: this.patchHeaders({})
        }, options);
    }

    public post<T>(url: string, options?: IFetcherOptions<T>): Promise<T> {
        return this.fetch(url, {
            method: "POST",
            headers: this.patchHeaders({}),
            body: "x"
        }, options);
    }

    public postText<T>(url: string, data: string, options?: IFetcherOptions<T>): Promise<T> {
        return this.fetch(url, {
            method: "POST",
            headers: this.patchHeaders({
                "Content-Type": "text/plain; charset=UTF-8"
            }),
            body: data
        }, options);
    }

    public get<T>(url: string, data?: unknown, options?: IFetcherOptions<T>): Promise<T> {
        return this.fetch(UrlBuilder.makeUnsafeHref(url, data as object), null, options);
    }
}

export default new Fetcher();
