import {IRoomCategory, RoomCategoryType} from "@skbkontur/hotel-data/roomCategory";
import {useMemoObject} from "@skbkontur/hotel-hooks/memo";
import styles from "./RoomCategoryComforts.scss";
import {RoomCategoryComfortsContext} from "./RoomCategoryComfortsContext";
import RoomCategoryComfortsGeneral from "./groups/RoomCategoryComfortsGeneral";
import RoomCategoryComfortsWindowView from "./groups/RoomCategoryComfortsWindowView";
import RoomCategoryComfortsBathroom from "./groups/RoomCategoryComfortsBathroom";
import RoomCategoryComfortsKitchen from "./groups/RoomCategoryComfortsKitchen";
import RoomCategoryComfortsUncategorized from "./groups/RoomCategoryComfortsUncategorized";
import RoomCategoryComfortsShowLink from "./components/RoomCategoryComfortsShowLink";

interface IRoomCategoryComfortsProps {
    roomCategory: IRoomCategory;
    showMoreMode?: boolean;
}

const KITCHEN_GROUP_CATEGORIES = [RoomCategoryType.Apartment, RoomCategoryType.Studio];

const RoomCategoryComforts = (props: IRoomCategoryComfortsProps) => {
    const {roomCategory, showMoreMode} = props;
    const {comforts, bathroomType, hasKitchen, roomCategoryType} = roomCategory;
    const {
        generalComforts,
        bathroomComforts,
        kitchenComforts,
        uncategorizedComforts,
        windowViewComforts
    } = comforts || {};


    const [showAll, setShowAll] = React.useState(showMoreMode || false);
    const toggleShowAll = () => setShowAll(showAll => !showAll);
    const contextValue = useMemoObject({showAll, toggleShowAll});

    const hasGeneralComforts = !!generalComforts?.length;
    const hasUncategorizedComforts = !!uncategorizedComforts?.length;
    const hasKitchenComforts = !!kitchenComforts?.length;
    const hasBathroomComforts = !!bathroomComforts?.length;
    const hasWindowViewComforts = !!windowViewComforts?.length;

    const isComfortsExist = [
        hasGeneralComforts,
        hasUncategorizedComforts,
        hasKitchenComforts,
        hasBathroomComforts,
        hasWindowViewComforts
    ].some(Boolean);

    if (!isComfortsExist)
        return null;

    const showKitchenGroup = (
        KITCHEN_GROUP_CATEGORIES.includes(roomCategoryType)
        || (roomCategoryType === RoomCategoryType.Other && hasKitchen)
    );

    const hasKitchenInUncategorized = !showKitchenGroup && hasKitchenComforts;
    const hasKitchenGroup = showKitchenGroup && hasKitchenComforts;
    const hasUnrecognizedGroup = hasUncategorizedComforts || hasKitchenInUncategorized;

    const getContent = () => {
        switch (true) {
            case hasGeneralComforts:
                return (
                    <>
                        <div className={styles.comfortsColumn}>
                            <RoomCategoryComfortsGeneral
                                comforts={generalComforts}
                                withShowLink={(
                                    hasWindowViewComforts
                                    || hasKitchenGroup
                                    || hasBathroomComforts
                                    || hasUnrecognizedGroup
                                )}
                            />
                            {hasUnrecognizedGroup && (
                                <RoomCategoryComfortsUncategorized
                                    uncategorizedComforts={uncategorizedComforts}
                                    kitchenComforts={hasKitchenInUncategorized ? kitchenComforts : []}
                                />
                            )}
                        </div>
                        <div className={styles.comfortsColumn}>
                            <RoomCategoryComfortsBathroom comforts={bathroomComforts} bathroomType={bathroomType}/>
                            {hasKitchenGroup && <RoomCategoryComfortsKitchen comforts={kitchenComforts}/>}
                            {hasWindowViewComforts && <RoomCategoryComfortsWindowView comforts={windowViewComforts}/>}
                        </div>
                    </>
                );
            case !hasGeneralComforts && hasUnrecognizedGroup:
                return (
                    <>
                        <div className={styles.comfortsColumn}>
                            <RoomCategoryComfortsUncategorized
                                uncategorizedComforts={uncategorizedComforts}
                                kitchenComforts={hasKitchenInUncategorized ? kitchenComforts : []}
                                withShowLink={hasKitchenGroup || hasBathroomComforts}
                                alwaysShown
                            />
                        </div>
                        <div className={styles.comfortsColumn}>
                            <RoomCategoryComfortsBathroom comforts={bathroomComforts} bathroomType={bathroomType}/>
                            {hasKitchenGroup && <RoomCategoryComfortsKitchen comforts={kitchenComforts}/>}
                            {hasWindowViewComforts && <RoomCategoryComfortsWindowView comforts={windowViewComforts}/>}
                        </div>
                    </>
                );
            case !hasGeneralComforts && !hasUnrecognizedGroup:
                return (
                    <>
                        <div className={styles.comfortsColumn}>
                            <RoomCategoryComfortsBathroom
                                comforts={bathroomComforts}
                                bathroomType={bathroomType}
                            />
                            {hasKitchenGroup && <RoomCategoryComfortsKitchen comforts={kitchenComforts}/>}
                            {(hasBathroomComforts || hasKitchenGroup) && <RoomCategoryComfortsShowLink/>}
                        </div>
                        <div className={styles.comfortsColumn}>
                            {hasWindowViewComforts && <RoomCategoryComfortsWindowView comforts={windowViewComforts}/>}
                        </div>
                    </>
                );
        }
    };

    return (
        <RoomCategoryComfortsContext.Provider value={contextValue}>
            <div className={styles.comforts} data-tid="Comforts">
                {getContent()}
            </div>
        </RoomCategoryComfortsContext.Provider>
    );
};
RoomCategoryComforts.displayName = "RoomCategoryComforts";
export default RoomCategoryComforts;
