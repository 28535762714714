import {LogoViberIcon16Regular} from "@skbkontur/icons/LogoViberIcon16Regular";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type ViberIconTypes = IconTypes.Regular16;

interface IViberIconProps extends IconProps {
    type: ViberIconTypes;
}

const ViberIcon = ({type, ...iconProps}: IViberIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <LogoViberIcon16Regular {...iconProps}/>;
    }
};
ViberIcon.displayName = "ViberIcon";
export default ViberIcon;
