import {LocationPinIcon16Solid} from "@skbkontur/icons/LocationPinIcon16Solid";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type MapPinIconTypes = IconTypes.Solid16;

interface IMapPinIconProps extends IconProps {
    type: MapPinIconTypes;
}

const MapPinIcon = ({type, ...iconProps}: IMapPinIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <LocationPinIcon16Solid {...iconProps}/>;
    }
};
MapPinIcon.displayName = "MapPinIcon";
export default MapPinIcon;
