import {TooltipProps} from "@skbkontur/react-ui/cjs/components/Tooltip/Tooltip";
import {Tooltip} from "@skbkontur/react-ui";

interface IOptionalTooltipProps extends Omit<TooltipProps, "data-tid"> {
    showTooltip: boolean;
    /** Required to use instead of data-tid to prevent data-tid fallback on children element */
    selector?: string;
}

const OptionalTooltip = (props: IOptionalTooltipProps) => {
    const {showTooltip, selector, ...tooltipProps} = props;
    const {children} = tooltipProps;

    return showTooltip ? (
        <Tooltip data-tid={selector} {...tooltipProps}/>
    ) : (
        <>{children}</>
    );
};
OptionalTooltip.displayName = "OptionalTooltip";
export default OptionalTooltip;