import {LocalStorageFallback} from "./LocalStorageFallback";

const localStorageImpl = localStorage || new LocalStorageFallback();

class LocalStorage {
    static get = <TValue = unknown>(key: string): TValue => {
        const value = localStorageImpl.getItem(key);
        return this.parse(value);
    };

    static parse = <TValue = unknown>(value: string): TValue => (
        value === null ? null : JSON.parse(value)
    );

    static set = <TValue = unknown>(key: string, value: TValue): void => {
        const oldValue = localStorageImpl.getItem(key);
        const newValue = JSON.stringify(value);
        localStorageImpl.setItem(key, newValue);
        window.dispatchEvent(new StorageEvent("storage", {key, oldValue, newValue}));
    };

    static update = <TValue extends object = object>(key: string, newValue: Partial<TValue>): void => {
        const prevValue = LocalStorage.get<TValue>(key) || {};
        LocalStorage.set<TValue>(key, {...prevValue, ...newValue} as TValue);
    };

    static remove = (key: string): void => {
        localStorageImpl.removeItem(key);
    };
}

export default LocalStorage;
