import {FlippedElement, FlipperHelper} from "@skbkontur/hotel-components/flipper";
import ItemWithBorder from "../ItemWithBorder/ItemWithBorder";
import styles from "./HourlyObjectsList.scss";

interface IHourlyObjectsListFlipperProps {
    hourlyObjectId: string;
    isLast: boolean;
}

const HourlyObjectsListFlipper = (props: React.PropsWithChildren<IHourlyObjectsListFlipperProps>) => {
    const {hourlyObjectId, isLast, children} = props;

    return (
        <FlippedElement
            flipId={hourlyObjectId}
            onAppear={FlipperHelper.onAppear(styles.fadeIn, 500)}
            withDelay
            withDiv
            withContext
        >
            <ItemWithBorder withoutBorder={isLast}>{children}</ItemWithBorder>
        </FlippedElement>
    );
};
HourlyObjectsListFlipper.displayName = "HourlyObjectsListFlipper";
export default React.memo(HourlyObjectsListFlipper);
