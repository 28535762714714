import {ValidationContainer} from "@skbkontur/react-ui-validations";
import cn from "classnames";
import {useTranslation} from "@skbkontur/i18n";
import {useEffectWithoutInitCall, useMount} from "@skbkontur/hotel-hooks/react";
import {useSelector} from "react-redux";
import {isEqual} from "lodash";
import {DateCalculate} from "@skbkontur/hotel-date";
import {DeviceContext, DeviceMode} from "../../providers/Device/DeviceContext";
import {mainSearchAnalyticsEvents} from "../../analytics/mainSearchAnalyticsEvents";
import {bookingLightboxSearchPanelAnalyticsEvents} from "../../analytics/bookingLightboxSearchPanelAnalyticsEvents";
import {IAppState} from "../../store/AppState";
import GuestsSelectControl from "../GuestsSelectControl/GuestsSelectControl";
import DatesPeriodControl from "../DatesPeriodControl/DatesPeriodControl";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IRoomCategorySearchParams} from "../../data/SearchParams";
import {useWidgetThemeContext} from "../../widget/components/WidgetTheme/WidgetThemeContext";
import {IThemeBookingForm} from "../../widget/theme/ThemeWidgets";
import BookingSearchFormButton from "./BookingSearchFormButton";
import styles from "./BookingSearchForm.scss";

export interface IBookingSearchFormProps {
    params: IRoomCategorySearchParams;
    onSearch: (params?: IRoomCategorySearchParams) => void;
    onChange?: (params?: IRoomCategorySearchParams) => void;
    inline?: boolean;
    autoFocus?: boolean;
    searchOnMount?: boolean;
    alwaysActiveButton?: boolean;
    onFrontPage?: boolean;
    noPaddings?: boolean;
}

export interface IBookingSearchFormState extends IRoomCategorySearchParams {
    needSearch: boolean;
}

const BookingSearchForm = (props: IBookingSearchFormProps) => {
    const {
        params,
        onSearch,
        onChange,
        searchOnMount,
        autoFocus,
        inline,
        alwaysActiveButton,
        onFrontPage,
        noPaddings
    } = props;

    const {t, tcn} = useTranslation(TranslationNamespaces.BookingModule);
    const {deviceMode} = React.useContext(DeviceContext);
    const {checkButton, periodPicker, inlayMode} = useWidgetThemeContext<IThemeBookingForm>();

    const offsetInMinutes = useSelector((state: IAppState) => state.hotelInfo.info?.timeZone?.offsetInMinutes);

    const [state, setState] = React.useState<IBookingSearchFormState>({
        ...params,
        needSearch: true
    });
    const {needSearch, ...stateParams} = state;
    const {fromDate, toDate, adultsCount, kidsCount, roomCategoryId} = stateParams;

    const containerRef = React.useRef<ValidationContainer>();
    const datePeriodRef = React.useRef<DatesPeriodControl>();

    useEffectWithoutInitCall(() => {
        setState(state => {
            const {needSearch, ...stateParams} = state;
            if (isEqual(params, stateParams))
                return state;
            return {...state, ...params};
        });
    }, [params]);

    const partialFormChange = (params: Partial<IRoomCategorySearchParams>) => {
        setState(state => {
            const newParams = {
                ...state,
                ...params,
                needSearch: true
            };
            if (onChange) {
                const {needSearch, ...searchParams} = newParams;
                onChange(searchParams);
            }
            return newParams;
        });
    };

    const handleSubmit = async () => {
        const isValid = await containerRef.current.validate();
        if (isValid) {
            const newState = {
                ...state,
                roomCategoryId: roomCategoryId || null,
                needSearch: false
            };
            setState(newState);
            const {needSearch, ...searchParams} = newState;
            onSearch(searchParams);
        }
    };

    useMount(() => {
        if (searchOnMount) {
            // TODO Переписать, когда будет binder. Сейчас так, чтобы не подсвечивать красным при открытии ЛБ брони.
            const {toDatePicker, fromDatePicker} = datePeriodRef.current.validate(t);
            const isValidationOk = !toDatePicker && !fromDatePicker;
            if (isValidationOk) {
                handleSubmit();
            }
        }
    });

    const onButtonSearch = () => {
        // alwaysActiveButton indicates search form in hotel page
        if (alwaysActiveButton) mainSearchAnalyticsEvents.trackSearchFormRunSearch();
        else bookingLightboxSearchPanelAnalyticsEvents.trackRestartSearch();
        handleSubmit();
    };

    const isButtonUsed = !alwaysActiveButton && !needSearch;

    const handleCheckinChange = (fromDate: string) => {
        const nightsCount = DateCalculate.getDiffByDays(fromDate, toDate);
        bookingLightboxSearchPanelAnalyticsEvents.trackDatePeriodChange(nightsCount);
        partialFormChange({fromDate});
    };

    const handleCheckoutChange = (toDate: string) => {
        const nightsCount = DateCalculate.getDiffByDays(fromDate, toDate);
        bookingLightboxSearchPanelAnalyticsEvents.trackDatePeriodChange(nightsCount);
        partialFormChange({toDate});
    };

    const handleAdultsChange = (adultsCount: number) => {
        bookingLightboxSearchPanelAnalyticsEvents.trackGuestsChange(adultsCount, kidsCount);
        partialFormChange({adultsCount});
    };

    const handleKidsChange = (kidsCount: number) => {
        bookingLightboxSearchPanelAnalyticsEvents.trackGuestsChange(adultsCount, kidsCount);
        partialFormChange({kidsCount});
    };

    const controlClasses = cn(styles.control, {
        [styles.inlay]: !!inlayMode,
        [styles.inline]: inline,
        [styles.controlOnFrontPage]: onFrontPage,
        [styles.noPaddings]: noPaddings
    });

    return (

        <div className={controlClasses}>
            <ValidationContainer ref={containerRef}>
                <div className={styles.controlsWrapper}>
                    <div className={styles.dates}>
                        <DatesPeriodControl
                            fromField={{
                                value: fromDate,
                                placeholder: tcn("checkin"),
                                onChange: handleCheckinChange,
                            }}
                            toField={{
                                value: toDate,
                                placeholder: tcn("checkout"),
                                onChange: handleCheckoutChange
                            }}
                            isAutoFocus={autoFocus}
                            offsetInMinutes={offsetInMinutes}
                            ref={ref => datePeriodRef.current = ref}
                            hideLabels={deviceMode === DeviceMode.Mobile}
                            theme={periodPicker}
                        />
                    </div>
                    <div className={styles.guestsSelect}>
                        <GuestsSelectControl
                            adults={{
                                count: adultsCount,
                                onChange: handleAdultsChange
                            }}
                            kids={{
                                count: kidsCount,
                                onChange: handleKidsChange
                            }}
                            onApply={() => datePeriodRef.current?.focusFirstEmptyPicker()}
                        />
                    </div>
                    <BookingSearchFormButton
                        theme={checkButton}
                        isButtonUsed={isButtonUsed}
                        onClick={onButtonSearch}
                    />
                </div>
            </ValidationContainer>
        </div>
    );
};
BookingSearchForm.displayName = "BookingSearchForm";
export default BookingSearchForm;
