import {ChildBabyIcon16Light} from "@skbkontur/icons/ChildBabyIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type BabyIconTypes = IconTypes.Light16 | IconTypes.Regular16;

interface IBabyIconProps extends IconProps {
    type: BabyIconTypes;
}

const BabyIcon = ({type, ...iconProps}: IBabyIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <ChildBabyIcon16Light {...iconProps}/>;
    }
};
BabyIcon.displayName = "BabyIcon";
export default BabyIcon;
