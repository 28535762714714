import {PlusIcon16Light} from "@skbkontur/icons/PlusIcon16Light";
import {PlusIcon16Regular} from "@skbkontur/icons/PlusIcon16Regular";
import {PlusIcon20Regular} from "@skbkontur/icons/PlusIcon20Regular";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type AddIconTypes = IconTypes.Light16 | IconTypes.Regular16 | IconTypes.Regular20;

interface IAddIconProps extends IconProps {
    type: AddIconTypes;
}

const AddIcon = ({type, ...iconProps}: IAddIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <PlusIcon16Light {...iconProps}/>;
        case IconTypes.Regular16:
            return <PlusIcon16Regular {...iconProps}/>;
        case IconTypes.Regular20:
            return <PlusIcon20Regular {...iconProps}/>;
    }
};
AddIcon.displayName = "AddIcon";
export default AddIcon;
