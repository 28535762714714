import {BedType, IBedStructure, IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import {AdditionalBedType} from "@skbkontur/hotel-data/roomCategory/RoomCategory";
import {OptionalTooltip} from "../../optionalTooltip";
import RoomCategoryLabelHint from "./components/RoomCategoryLabelHint";
import RoomCategoryLabelIcons from "./components/RoomCategoryLabelIcons";
import styles from "./RoomCategoryLabel.scss";

interface IRoomCategoryCharacteristicsProps {
    category: IRoomCategory;
    hideArea?: boolean;
    small?: boolean;
    withoutTooltip?: boolean;
}

const RoomCategoryLabel = (props: IRoomCategoryCharacteristicsProps) => {
    const {category, hideArea, small, withoutTooltip} = props;
    const {rooms, roomCategoryType, placesMin, placesMax} = category;

    const allBeds = rooms?.reduce((beds, room) => [
        ...beds,
        ...(room.beds?.filter(bed => bed.type) || [])
    ], [] as IBedStructure<BedType>[]);

    const allAdditionalBeds = rooms?.reduce((beds, room) => [
        ...beds,
        ...(room.additionalBeds?.filter(bed => bed.type) || [])
    ], [] as IBedStructure<AdditionalBedType>[]);

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const renderTooltip = () => (
        RoomCategoryHelper.isHostel(roomCategoryType) ? (
            <div data-tid="TooltipHostelPlacesText">
                {placesMax === placesMin
                    ? t("hostelPlacesCount", {
                        reactParams: {
                            PlacesMin: <span data-tid="TooltipHostelPlacesMin">{placesMin}</span>
                        },
                        count: placesMin
                    })
                    : t("hostelPlacesCountRange", {
                        reactParams: {
                            PlacesMin: <span data-tid="TooltipHostelPlacesMin">{placesMin}</span>,
                            PlacesMax: <span data-tid="TooltipHostelPlacesMax">{placesMax}</span>,
                        },
                    })}
            </div>
        ) : (
            <RoomCategoryLabelHint
                category={category}
                beds={allBeds}
                additionalBeds={allAdditionalBeds}
            />
        )
    );

    const hasSomeBeds = !!allBeds?.length || !!allAdditionalBeds?.length;
    const isHostelWithPlaces = RoomCategoryHelper.isHostel(roomCategoryType) && !!placesMin;

    const icons = (
        <RoomCategoryLabelIcons
            category={category}
            hideArea={hideArea}
            beds={allBeds}
            additionalBeds={allAdditionalBeds}
            small={small}
        />
    );

    return (
        <div data-tid="RoomCategoryLabel">
            <OptionalTooltip
                showTooltip={!withoutTooltip && (hasSomeBeds || isHostelWithPlaces)}
                useWrapper
                pos="top left"
                render={renderTooltip}
                className={styles.tooltip}
                selector="RoomCategoryLabelTooltip"
            >
                {icons}
            </OptionalTooltip>
        </div>
    );
};
RoomCategoryLabel.displayName = "RoomCategoryLabel";
export default RoomCategoryLabel;
