import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";
import Fetcher from "../core/Fetcher";
import {Compare} from "../helpers/CompareHelper";
import {IRate} from "../data/Rate";
import {IHotelFeature} from "../data/HotelFeature";
import {IRoomCategorySearchParams} from "../data/SearchParams";
import {IRoomCategoryAccommodation} from "../data/Accommodation";
import {BookingKind} from "../data/BookingKind";
import {ApiBase} from "./apiBase";
import {ApiSiteBase} from "./apiSiteBase";
import {ImageApi} from "./image";
 
export class DailyApi extends ApiSiteBase {
    getEntities = async (): Promise<IRoomCategory[]> => {
        const roomCategories = await Fetcher.get<IRoomCategory[]>(this.buildRequestUrl(["entities"]));
        const roomCategoriesWithImages = roomCategories.map(rc => {
            const {imageMetas, ...roomCategory} = rc;
            ImageApi.sortMetas(imageMetas);
            return {
                ...roomCategory,
                imageMetas,
            };
        });
        return roomCategoriesWithImages.sort(Compare.byOrder);
    };

    getRates = (): Promise<IRate[]> => (
        Fetcher.get<IRate[]>(this.buildRequestUrl(["rates"]))
    );

    getFeatures = async (): Promise<IHotelFeature[]> => (
        await Fetcher.get<IHotelFeature[]>(this.buildRequestUrl(["features"]))
    );

    getAccommodations = (params: IRoomCategorySearchParams): Promise<IRoomCategoryAccommodation[]> => {
        const query = ApiBase.toQueryParams({
            ...params,
            fromDate: ApiBase.toQueryDate(params.fromDate),
            toDate: ApiBase.toQueryDate(params.toDate),
        });
        return Fetcher.get<IRoomCategoryAccommodation[]>(this.buildRequestUrl(["accommodations", query]));
    };
}

export default new DailyApi(BookingKind.Daily);
