import {TimeClockIcon16Regular} from "@skbkontur/icons/TimeClockIcon16Regular";
import {TimeClockIcon20Regular} from "@skbkontur/icons/TimeClockIcon20Regular";
import {TimeClockIcon16Light} from "@skbkontur/icons/TimeClockIcon16Light";
import {TimeClockIcon20Solid} from "@skbkontur/icons/TimeClockIcon20Solid";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type ClockIconTypes = IconTypes.Regular16 | IconTypes.Regular20 | IconTypes.Light16 | IconTypes.Solid20;

interface IClockIconProps extends IconProps {
    type: ClockIconTypes;
}

const ClockIcon = ({type, ...iconProps}: IClockIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <TimeClockIcon16Regular {...iconProps}/>;
        case IconTypes.Regular20:
            return <TimeClockIcon20Regular {...iconProps}/>;
        case IconTypes.Light16:
            return <TimeClockIcon16Light {...iconProps}/>;
        case IconTypes.Solid20:
            return <TimeClockIcon20Solid {...iconProps}/>;
    }
};
ClockIcon.displayName = "ClockIcon";
export default ClockIcon;
