import {ArrowFigureACircleDownIcon32Regular} from "@skbkontur/icons/ArrowFigureACircleDownIcon32Regular";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type ArrowDownIconTypes = IconTypes.Regular32;

interface IArrowFigureCircleDownIconProps extends IconProps {
    type: ArrowDownIconTypes;
}

const ArrowFigureCircleDownIcon = ({type, ...iconProps}: IArrowFigureCircleDownIconProps) => {
    switch (type) {
        case IconTypes.Regular32:
            return <ArrowFigureACircleDownIcon32Regular {...iconProps}/>;
    }
};
ArrowFigureCircleDownIcon.displayName = "ArrowFigureCircleDownIcon";
export default ArrowFigureCircleDownIcon;
