import {Gapped} from "@skbkontur/react-ui";
import cn from "classnames";
import {useTranslation} from "@skbkontur/i18n";
import {IconTypes, ArrowFigureCircleDownIcon} from "@skbkontur/hotel-components/icon";
import {RollUp} from "@skbkontur/hotel-components/rollUp";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IHotelFeature} from "../../data/HotelFeature";
import {Compare} from "../../helpers/CompareHelper";
import {HotelFeaturesMap} from "../../data/Booking";
import BookingRatesHotelFeatureItem from "./BookingRatesHotelFeatureItem";
import styles from "./BookingRatesHotelFeatures.scss";

interface IBookingRatesHotelFeaturesProps {
    title?: string | React.ReactNode;
    rateId: string;
    hotelFeaturesMap: HotelFeaturesMap;
    guestsCount?: number;
    sellingHotelFeatures: IHotelFeature[];
    updateHotelFeature: (hotelFeatureId: string, newCount: number) => void;
}

const BookingRatesHotelFeatures = (props: IBookingRatesHotelFeaturesProps) => {
    const {title, rateId, hotelFeaturesMap, sellingHotelFeatures, guestsCount, updateHotelFeature} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);

    const [isOpened, setIsOpened] = React.useState<boolean>(false);

    const sortByIncludingInRate = (a: IHotelFeature, b: IHotelFeature) => Compare.byIncludingInRate(a, b, rateId);

    const sortedHotelFeatures = React.useMemo(
        () => sellingHotelFeatures.sort(sortByIncludingInRate),
        [sellingHotelFeatures]
    );
    const sortedHotelFeaturesLength = sortedHotelFeatures.length;

    const alwaysShownHotelFeatures = React.useMemo(
        () => sortedHotelFeaturesLength > 3 ? sortedHotelFeatures.slice(0, 3) : sortedHotelFeatures,
        [sortedHotelFeatures]
    );

    const rollUpHotelFeatures = React.useMemo(
        () => sortedHotelFeaturesLength > 3 ? sortedHotelFeatures.slice(3, sortedHotelFeaturesLength) : [],
        [sortedHotelFeatures]
    );

    const imageClassName = cn(styles.image, {[styles.onClick]: isOpened});

    const handleClickOnImage = () => {
        setIsOpened(isOpened => !isOpened);
    };

    return (
        <div className={styles.container}>
            {title && (
                <div className={styles.title}>
                    {title}
                </div>
            )}
            <div className={styles.items}>
                {alwaysShownHotelFeatures.map(hotelFeature => (
                    <BookingRatesHotelFeatureItem
                        rateId={rateId}
                        guestsCount={guestsCount}
                        key={hotelFeature.id + rateId}
                        hotelFeature={hotelFeature}
                        hotelFeaturesMap={hotelFeaturesMap}
                        updateHotelFeature={updateHotelFeature}
                    />
                ))}
                {/*TODO@jilyaev: would be better to use Flipper here. but now i dont know how to make it pretty so here used rollUp :) */}
                <RollUp isShown={isOpened}>
                    <Gapped gap={20} vertical>
                        {rollUpHotelFeatures.map(hotelFeature => (
                            <BookingRatesHotelFeatureItem
                                rateId={rateId}
                                guestsCount={guestsCount}
                                key={hotelFeature.id + rateId}
                                hotelFeature={hotelFeature}
                                hotelFeaturesMap={hotelFeaturesMap}
                                updateHotelFeature={updateHotelFeature}
                            />
                        ))}
                    </Gapped>
                </RollUp>
                {sortedHotelFeatures.length > 3 && (
                    <div onClick={handleClickOnImage} className={styles.rollupButton}>
                        <Gapped gap={10} verticalAlign="middle">
                            <div className={imageClassName}>
                                <ArrowFigureCircleDownIcon type={IconTypes.Regular32}/>
                            </div>
                            <div className={styles.rollupCaption}>
                                {isOpened
                                    ? t("hotelFeatures.collapseHotelFeatures")
                                    : t("hotelFeatures.showAllHotelFeatures")}
                            </div>
                        </Gapped>
                    </div>
                )}
            </div>
        </div>
    );
};

BookingRatesHotelFeatures.displayName = "BookingRatesHotelFeatures";
export default BookingRatesHotelFeatures;
