import {TechPhoneClassicIcon16Solid} from "@skbkontur/icons/TechPhoneClassicIcon16Solid";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {TechPhoneClassicIcon16Light} from "@skbkontur/icons/TechPhoneClassicIcon16Light";
import {IconTypes} from "./Icon";

type PhoneIconTypes = IconTypes.Solid16 | IconTypes.Light16;

interface IPhoneIconProps extends IconProps {
    type: PhoneIconTypes;
}

const PhoneIcon = ({type, ...iconProps}: IPhoneIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <TechPhoneClassicIcon16Solid {...iconProps}/>;
        case IconTypes.Light16:
            return <TechPhoneClassicIcon16Light {...iconProps}/>;
    }
};
PhoneIcon.displayName = "PhoneIcon";
export default PhoneIcon;
