export interface IRoomCategoryComforts {
    uncategorizedComforts: UncategorizedComfortType[];
    generalComforts: GeneralComfortType[];
    windowViewComforts: WindowViewComfortType[];
    bathroomComforts: BathroomComfortType[];
    kitchenComforts: KitchenComfortType[];
}

export enum GeneralComfortType {
    Conditioner = "Conditioner",
    WiFi = "WiFi",
    Tv = "Tv",
    Telephone = "Telephone",
    Safe = "Safe"
}

export enum UncategorizedComfortType {
    MiniBar = "MiniBar",
    MiniFridge = "MiniFridge",
    SofaOrArmchair = "SofaOrArmchair",
    WritingDesk = "WritingDesk",
    Iron = "Iron",
    IroningBoard = "IroningBoard",
    Chimney = "Chimney",
    Terrace = "Terrace",
    BbqGrill = "BbqGrill",
    PrivatePool = "PrivatePool",
}

export enum WindowViewComfortType {
    CityView = "CityView",
    GardenView = "GardenView",
    LakeView = "LakeView",
    RiverView = "RiverView",
    PoolView = "PoolView",
    MountainsView = "MountainsView",
    SeaView = "SeaView",
}

export enum BathroomComfortType {
    Shower = "Shower",
    Bathtub = "Bathtub",
    Jacuzzi = "Jacuzzi",
    Bidet = "Bidet",
    Hairdryer = "Hairdryer",
    WashingMachine = "WashingMachine",
    Bathrobe = "Bathrobe",
    Slippers = "Slippers",
    BathroomAccessories = "BathroomAccessories",
}

export enum KitchenComfortType {
    Furniture = "Furniture",
    Refrigerator = "Refrigerator",
    Kettle = "Kettle",
    Dishwasher = "Dishwasher",
    Microwave = "Microwave",
    Toaster = "Toaster",
    Kitchenware = "Kitchenware",
}

export type KnownRoomCategoryComforts = UncategorizedComfortType
    | GeneralComfortType
    | WindowViewComfortType
    | BathroomComfortType
    | KitchenComfortType;