import {CSSTransition} from "react-transition-group";
import {useRef} from "@skbkontur/hotel-hooks/react";

export interface IRollUpProps {
    isShown: boolean;
    duration?: number;
    paddingTop?: number;
    paddingBottom?: number;
    offsetLeft?: number;
    offsetRight?: number;
}

const DEFAULT_TIMEOUT = 200;

const RollUp = (props: React.PropsWithChildren<IRollUpProps>) => {
    const {
        isShown,
        duration = DEFAULT_TIMEOUT,
        children,
        paddingTop = 0,
        paddingBottom = 0,
        offsetLeft = 0,
        offsetRight = 0
    } = props;

    const [visibleHeightRef, setVisibleHeight] = useRef<number>();
    const [timeoutRef, setTimeoutRef] = useRef<NodeJS.Timeout>();

    const contentRef = React.useRef<HTMLDivElement>();
    const containerRef = React.useRef<HTMLDivElement>();

    const transition = `cubic-bezier(0.5, 1, 0.89, 1) ${duration}ms`;

    const handleEnter = () => {
        clearTimeout(timeoutRef.current);
        containerRef.current.style.display = "block";
        const {current: content} = contentRef;
        content.style.transition = "none";
        content.style.overflow = "auto";
        content.style.maxHeight = "auto";
        setVisibleHeight(content.clientHeight || visibleHeightRef.current);
        content.style.overflow = "hidden";
        content.style.maxHeight = "0";
        content.style.opacity = "0.01";
    };

    const handleEntering = () => {
        const {current: content} = contentRef;
        content.style.transition = transition;
        content.style.maxHeight = `${visibleHeightRef.current}px`;
        content.style.opacity = "1";
    };

    const handleEntered = () => {
        const {current: content} = contentRef;
        content.style.transition = "none";
        content.style.overflow = "visible";
        content.style.maxHeight = "none";
    };

    const handleExiting = () => {
        const {current: content} = contentRef;
        content.style.transition = transition;
        content.style.maxHeight = "0";
        content.style.opacity = "0.01";
    };

    const handleExit = () => {
        const {current: content} = contentRef;
        content.style.transition = "none";
        content.style.maxHeight = content.clientHeight + "px";
        content.style.overflow = "hidden";
        content.style.opacity = "1";
        const timerId = setTimeout(() => {
            containerRef.current.style.display = "none";
        }, duration);
        setTimeoutRef(timerId);
    };

    return (
        <div ref={containerRef} style={{marginLeft: -offsetLeft, marginRight: -offsetRight}}>
            <CSSTransition
                classNames="rollUp"
                in={isShown}
                timeout={duration || DEFAULT_TIMEOUT}
                unmountOnExit
                onEnter={handleEnter}
                onEntering={handleEntering}
                onEntered={handleEntered}
                onExit={handleExit}
                onExiting={handleExiting}
                nodeRef={contentRef}
            >
                <div ref={contentRef}>
                    <div style={{
                        paddingTop,
                        paddingBottom,
                        paddingLeft: offsetLeft,
                        paddingRight: offsetRight,
                    }}
                    >
                        {children}
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};
RollUp.displayName = "RollUp";
export default RollUp;
