import {hotelDate, HotelDate} from "../hotelDate";
import {ConfigDateType, UnitOfTime} from "./Date";
import {DateFormat} from "./DateFormat";
import {DateHelper} from "./DateHelper";
import {IBaseArgs, IWithUnitOfTime} from "./DateInterface";

interface IAddArgs extends IBaseArgs, IWithUnitOfTime {
    amount: number;
    newHours?: number;
    formatOut?: DateFormat;
}

interface IBaseWithOffsetInMinutesArgs extends IBaseArgs {
    offsetInMinutes: number;
}

export class DateCalculate {
    static getDate = (year: number, monthFromZero: number, day: number, format: DateFormat): string => (
        hotelDate(new Date(year, monthFromZero, day).toISOString(), DateFormat.FullDateYearFirstWithTimeWithSecondsWithTimeZone).format(format)
    );

    static getDateAsHotelTime = (args: IBaseWithOffsetInMinutesArgs): string => {
        const {date, offsetInMinutes, format} = args;
        return DateCalculate.add({
            date,
            amount: offsetInMinutes,
            unitOfTime: UnitOfTime.Minute,
            format
        });
    };

    static add = (args: IAddArgs): string => {
        const {
            date,
            format,
            amount,
            unitOfTime = UnitOfTime.Day,
            newHours = null,
            formatOut = format
        } = args;

        const fromDateDayJs = date ? hotelDate(date, format) : hotelDate();
        const newDate = hotelDate(fromDateDayJs, format).add(amount, unitOfTime);

        return newHours !== null
            ? newDate.hour(newHours).format(formatOut)
            : newDate.format(formatOut);
    };

    static getDateAsUtc = (args: IBaseWithOffsetInMinutesArgs): string => {
        const {format, date, offsetInMinutes} = args;
        return DateCalculate.add({
            date,
            amount: -offsetInMinutes,
            unitOfTime: UnitOfTime.Minute,
            format
        });
    };

    static getCurrentHotelDayjs = (offsetInMinutes: number): HotelDate => (
        hotelDate().utcOffset(offsetInMinutes)
    );

    static getStartOfCurrentMonth(format: DateFormat = DateFormat.FullDateDayFirst): string {
        return hotelDate().startOf(UnitOfTime.Month).format(format);
    }

    static getEndOfCurrentMonth(format: DateFormat = DateFormat.FullDateDayFirst): string {
        return hotelDate().endOf(UnitOfTime.Month).format(format);
    }

    static getStartOfCurrentYear(format: DateFormat = DateFormat.FullDateDayFirst): string {
        return hotelDate().startOf(UnitOfTime.Year).format(format);
    }

    static getEndOfCurrentYear(format: DateFormat = DateFormat.FullDateDayFirst): string {
        return hotelDate().endOf(UnitOfTime.Year).format(format);
    }

    static getDiffByDays = (firstDate: ConfigDateType, secondDate: ConfigDateType) => (
        hotelDate(secondDate, DateFormat.FullDateDayFirst).diff(
            hotelDate(firstDate, DateFormat.FullDateDayFirst),
            UnitOfTime.Day
        )
    );

    static getDiffByHours = (
        firstDate: ConfigDateType,
        secondDate: ConfigDateType,
        format = DateFormat.FullDateYearFirstWithTimeWithSecondsWithTimeZone
    ) => hotelDate(secondDate, format).diff(hotelDate(firstDate, format), UnitOfTime.Hour);

    static getToday = (format: DateFormat = DateFormat.FullDateDayFirst) => hotelDate().format(format);

    static getTodayWithTimezone = (format: DateFormat, offsetInMinutes: number = DateHelper.getOffsetInMinutes()) => (
        hotelDate().utcOffset(offsetInMinutes).format(format)
    );

    static getTomorrow(format: DateFormat = DateFormat.FullDateDayFirst): string {
        return hotelDate().add(1, UnitOfTime.Day).format(format);
    }

    static getYesterday(format: DateFormat = DateFormat.FullDateDayFirst): string {
        return hotelDate().add(-1, "days").format(format);
    }

    static getEndOfDay = (
        date: string,
        formatIn: DateFormat = DateFormat.FullDateYearFirstWithTimeWithSecondsWithTimeZone,
        formatOut: DateFormat = formatIn
    ): string => (
        hotelDate(date, formatIn).endOf(UnitOfTime.Day).format(formatOut)
    );

    static getFirstMonthDate = (date: ConfigDateType, format: DateFormat): string => (
        hotelDate(date, format).startOf(UnitOfTime.Month).format(format)
    );

    static getLastMonthDate = (date: ConfigDateType, format: DateFormat): string => (
        hotelDate(date, format).endOf(UnitOfTime.Month).format(format)
    );
}
