import {DatePicker} from "@skbkontur/react-ui";
import {ValidationInfo} from "@skbkontur/react-ui-validations";
import cn from "classnames";
import {KonturI18NContext, Translated, tType} from "@skbkontur/i18n";
import {DateCalculate, DateCompare, DateFormat, UnitOfTime} from "@skbkontur/hotel-date";
import {createValidationInfo} from "../../forms/ValidationInfo";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import {IThemeElementPeriod} from "../../widget/theme/ThemeWidgets";
import {ThemeHelper} from "../../widget/theme/ThemeHelper";
import DatesPeriodPicker from "./DatesPeriodPickerControl";
import styles from "./DatesPeriodControl.scss";

export interface IDatePeriodField {
    value: string;
    placeholder: React.ReactNode;
    onChange: (value: string) => void;
}

interface IDatesPeriodControlProps {
    fromField: IDatePeriodField;
    toField: IDatePeriodField;
    isAutoFocus?: boolean;
    hideLabels?: boolean;
    offsetInMinutes?: number
    onValidate?: (validationInfo: IDatePeriodValidationInfo) => void;
    theme?: IThemeElementPeriod;
}

export interface IDatePeriodValidationInfo {
    fromDatePicker: ValidationInfo;
    toDatePicker: ValidationInfo;
}

interface IDatesPeriodChanges {
    current: IDatePeriodField;
    prev: IDatePeriodField;
}

interface IDatesValidationInfo {
    required: ValidationInfo;
    orderError: ValidationInfo;
    equalError: ValidationInfo;
    checkinBeforeToday: ValidationInfo;
    checkoutBeforeToday: ValidationInfo;
    selectDateNoLaterThan: ValidationInfo;
}

export const getDatesPeriodValidationInfo = (t: tType, maxDate: string): IDatesValidationInfo => ({
    required: createValidationInfo(t("validations.emptyCheckinCheckout")),
    orderError: createValidationInfo(t("validations.checkoutBeforeCheckin")),
    equalError: createValidationInfo(t("validations.checkoutEqualsCheckin")),
    checkinBeforeToday: createValidationInfo(t("validations.checkinBeforeNow")),
    checkoutBeforeToday: createValidationInfo(t("validations.checkoutBeforeNow")),
    selectDateNoLaterThan: createValidationInfo(t("validations.selectDateNoLaterThan", {date: maxDate}))
});

export default class DatesPeriodControl extends React.Component<IDatesPeriodControlProps> {
    static MIN_PERIOD_DAYS = 1;
    static MAX_PERIOD_YEAR = 2;

    fromDatePickerRef: DatesPeriodPicker;
    toDatePickerRef: DatesPeriodPicker;

    componentDidMount() {
        if (this.props.isAutoFocus) {
            // Use Promise async-call to avoid Modal autofocus phantom bug
            Promise.resolve().then(this.focusFirstEmptyPicker);
        }
    }

    componentDidUpdate(prevProps: IDatesPeriodControlProps) {
        const {fromField, toField} = this.props;
        const fromFieldChanges = {current: fromField, prev: prevProps.fromField};
        const toFieldChanges = {current: toField, prev: prevProps.toField};
        if (this.isFieldValid(fromField.value) && this.isFieldValid(toField.value)) {
            this.invalidateIfOrderIsNotCorrect(fromFieldChanges, toFieldChanges);
        } else {
            this.focusEmptyAfterChange(fromFieldChanges, toFieldChanges);
        }
    }

    focusEmptyAfterChange(fromField: IDatesPeriodChanges, toField: IDatesPeriodChanges) {
        const isAnotherFocusDetected = this.focusIfAnotherEmpty(fromField, toField.current.value, this.toDatePickerRef);
        if (!isAnotherFocusDetected) {
            this.focusIfAnotherEmpty(toField, fromField.current.value, this.fromDatePickerRef);
        }
    }

    focusIfAnotherEmpty(changes: IDatesPeriodChanges, anotherValue: string, anotherRef: DatesPeriodPicker) {
        if (
            this.isFieldValid(changes.current.value)
            && this.isFieldChanged(changes)
            && !this.isFieldValid(anotherValue)
        ) {
            anotherRef.focusPicker();
            return true;
        }
        return false;
    }

    focusFirstEmptyPicker = () => {
        const {fromField, toField} = this.props;
        const isAnyAlreadyFocused = this.fromDatePickerRef.isFocused() || this.toDatePickerRef.isFocused;
        if (!isAnyAlreadyFocused) {
            if (!this.isFieldValid(fromField.value)) {
                this.fromDatePickerRef.focusPicker();
            } else if (!this.isFieldValid(toField.value)) {
                this.toDatePickerRef.focusPicker();
            }
        }
    };

    invalidateIfOrderIsNotCorrect(fromField: IDatesPeriodChanges, toField: IDatesPeriodChanges) {
        if (!this.isCorrectOrder(fromField.current.value, toField.current.value)) {
            const isNotActualDetected = this.invalidateFirstIfSecondChanged(fromField, toField, this.fromDatePickerRef);
            if (!isNotActualDetected) {
                this.invalidateFirstIfSecondChanged(toField, fromField, this.toDatePickerRef);
            }
        }
    }

    invalidateFirstIfSecondChanged(
        first: IDatesPeriodChanges,
        second: IDatesPeriodChanges,
        firstRef: DatesPeriodPicker
    ) {
        if (!this.isFieldChanged(first) && this.isFieldChanged(second)) {
            first.current.onChange(null);
            firstRef.focusPicker();
            return true;
        }
        return false;
    }

    isFieldChanged = (changes: IDatesPeriodChanges) => changes.current.value !== changes.prev.value;
    isFieldValid = (value: string) => (
        DatePicker.validate(value) && (value.length === String(DateFormat.FullDateDayFirst).length)
    );

    getMinDateTo = (today: string): string => {
        const {fromField, toField} = this.props;

        if (!toField?.value && fromField?.value) {
            return DateCalculate.add({
                amount:
                DatesPeriodControl.MIN_PERIOD_DAYS,
                date: fromField?.value,
                format: DateFormat.FullDateDayFirst,
                unitOfTime: UnitOfTime.Day
            });
        }

        return DateCalculate.add({
            amount: DatesPeriodControl.MIN_PERIOD_DAYS,
            date: today,
            format: DateFormat.FullDateDayFirst,
            unitOfTime: UnitOfTime.Day
        });
    };

    render() {
        const {fromField, toField, hideLabels, offsetInMinutes, theme} = this.props;
        const {separatorText, fromText, toText} = theme || {};

        const today = DateCalculate.getTodayWithTimezone(DateFormat.FullDateDayFirst, offsetInMinutes);
        const maxDate = DateCalculate.add({
            amount:
            DatesPeriodControl.MAX_PERIOD_YEAR,
            date: today,
            format: DateFormat.FullDateDayFirst
            ,
            unitOfTime: UnitOfTime.Year
        });

        return (
            <KonturI18NContext.Consumer>
                {({locale}) => (
                    <Translated ns={[TranslationNamespaces.BookingModule]}>
                        {(t: tType) => {
                            // TODO Use "useThemeTextRender" when this control will be rewritten to function-style
                            const fromLocaleText = fromText?.[locale];
                            const toLocaleText = toText?.[locale];
                            const separatorLocaleText = separatorText?.[locale];

                            ThemeHelper.validatePeriodPicker(separatorLocaleText, fromLocaleText, toLocaleText);
                            const fromToMode = !separatorLocaleText && fromLocaleText && toLocaleText;

                            return (
                                <div
                                    className={cn(styles.control, {
                                        [styles.noLabelsMode]: !!hideLabels,
                                        [styles.dashMode]: !fromToMode && !hideLabels,
                                        [styles.labelsMode]: !!fromToMode && !hideLabels
                                    })}
                                >
                                    {fromToMode && !hideLabels && (
                                        <div className={styles.dateFromText}>{fromLocaleText}</div>
                                    )}
                                    <div className={styles.dateFrom}>
                                        <DatesPeriodPicker
                                            field={fromField}
                                            validationInfo={this.validate(t).fromDatePicker}
                                            minDate={today}
                                            maxDate={maxDate}
                                            ref={this.setFromDatePickerRef}
                                        />
                                    </div>
                                    {!fromToMode && !hideLabels && (
                                        <div className={styles.dash}>{separatorLocaleText || <>&ndash;</>}</div>
                                    )}
                                    {fromToMode && !hideLabels && (
                                        <div className={styles.dateToText}>{toLocaleText}</div>
                                    )}
                                    <div className={styles.dateTo}>
                                        <DatesPeriodPicker
                                            field={toField}
                                            validationInfo={this.validate(t).toDatePicker}
                                            minDate={this.getMinDateTo(today)}
                                            maxDate={maxDate}
                                            ref={this.setToDatePickerRef}
                                        />
                                    </div>
                                </div>
                            );
                        }}
                    </Translated>
                )}
            </KonturI18NContext.Consumer>
        );
    }

    isCorrectOrder = (fromField: string, toField: string) => (
        DateCalculate.getDiffByDays(fromField, toField) >= DatesPeriodControl.MIN_PERIOD_DAYS
    );

    validate = (t: tType): IDatePeriodValidationInfo => {
        const {offsetInMinutes} = this.props;
        const format = DateFormat.FullDateDayFirst;
        const today = DateCalculate.getTodayWithTimezone(format, offsetInMinutes);
        const maxDate = DateCalculate.add({
            amount: DatesPeriodControl.MAX_PERIOD_YEAR,
            date: today,
            format,
            unitOfTime: UnitOfTime.Year
        });
        const validationInfo = getDatesPeriodValidationInfo(t, maxDate);
        const {fromField, toField, onValidate} = this.props;
        const fromDate = fromField.value;
        const toDate = toField.value;
        const result = {} as IDatePeriodValidationInfo;

        if (DateCompare.isBefore({firstDate: maxDate, secondDate: fromDate, format})) {
            result.fromDatePicker = validationInfo.selectDateNoLaterThan;
        }

        if (DateCompare.isBefore({firstDate: maxDate, secondDate: toDate, format})) {
            result.toDatePicker = validationInfo.selectDateNoLaterThan;
        }

        if (fromDate && toDate && validationInfo.orderError && !this.isCorrectOrder(fromDate, toDate)) {
            result.toDatePicker = validationInfo.orderError;
        }

        if (fromDate && toDate && validationInfo.orderError && DateCompare.isSame({
            firstDate: toDate,
            secondDate: fromDate,
            format
        })) {
            result.toDatePicker = validationInfo.equalError;
        }

        if (fromDate && validationInfo.checkinBeforeToday && DateCompare.isBefore({
            firstDate: fromDate,
            secondDate: today,
            format
        })) {
            result.fromDatePicker = validationInfo.checkinBeforeToday;
        }

        if (toDate && validationInfo.checkoutBeforeToday && DateCompare.isBefore({
            firstDate: toDate,
            secondDate: today,
            format
        })) {
            result.toDatePicker = validationInfo.checkoutBeforeToday;
        }

        if (!fromDate && validationInfo.required) {
            result.fromDatePicker = validationInfo.required;
        }

        if (!toDate && validationInfo.required) {
            result.toDatePicker = validationInfo.required;
        }

        onValidate?.(result);
        return result;
    };

    private setFromDatePickerRef = (ref: DatesPeriodPicker) => this.fromDatePickerRef = ref;

    private setToDatePickerRef = (ref: DatesPeriodPicker) => this.toDatePickerRef = ref;
}
