import {MarketShoppingCartIcon16Solid} from "@skbkontur/icons/MarketShoppingCartIcon16Solid";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type ShoppingCartIconTypes = IconTypes.Solid16;

interface IShoppingCartIconProps extends IconProps {
    type: ShoppingCartIconTypes;
}

const ShoppingCartIcon = ({type, ...iconProps}: IShoppingCartIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <MarketShoppingCartIcon16Solid {...iconProps}/>;
    }
};
ShoppingCartIcon.displayName = "ShoppingCartIcon";
export default ShoppingCartIcon;
