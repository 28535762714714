import {SecuritySafeIcon16Light} from "@skbkontur/icons/SecuritySafeIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type SafeIconTypes = IconTypes.Light16;

interface ISafeIconProps extends IconProps {
    type: SafeIconTypes;
}

const SafeIcon = ({type, ...iconProps}: ISafeIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <SecuritySafeIcon16Light {...iconProps}/>;
    }
};
SafeIcon.displayName = "SafeIcon";
export default SafeIcon;