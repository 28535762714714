import {TechScreenMonitorIcon16Light} from "@skbkontur/icons/TechScreenMonitorIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type TvIconTypes = IconTypes.Light16;

interface ITvIconProps extends IconProps {
    type: TvIconTypes;
}

const TvIcon = ({type, ...iconProps}: ITvIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <TechScreenMonitorIcon16Light {...iconProps}/>;
    }
};
TvIcon.displayName = "TvIcon";
export default TvIcon;