import {UncategorizedComfortType, KitchenComfortType} from "@skbkontur/hotel-data/roomCategory";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import RoomCategoryComfortsGroup from "../components/RoomCategoryComfortsGroup";
import {RoomCategoryComfortsGroupType} from "../components/RoomCategoryComfortsGroupContext";
import RoomCategoryComfortsItem from "../components/RoomCategoryComfortsItem";
import {RollUp} from "../../../rollUp";
import {RoomCategoryComfortsContext} from "../RoomCategoryComfortsContext";
import RoomCategoryComfortsShowLink from "../components/RoomCategoryComfortsShowLink";
import styles from "../RoomCategoryComforts.scss";

interface IRoomCategoryComfortsUncategorizedProps {
    uncategorizedComforts: UncategorizedComfortType[];
    kitchenComforts: KitchenComfortType[];
    alwaysShown?: boolean;
    withShowLink?: boolean;
}

const SHORT_LIST_COUNT = 3;

const RoomCategoryComfortsUncategorized = (props: IRoomCategoryComfortsUncategorizedProps) => {
    const {uncategorizedComforts, kitchenComforts, alwaysShown, withShowLink} = props;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);
    const {showAll} = React.useContext(RoomCategoryComfortsContext);

    const renderUncategorized = (comfortType: UncategorizedComfortType) => (
        <RoomCategoryComfortsItem
            key={comfortType}
            groupType={RoomCategoryComfortsGroupType.Unrecognized}
            comfortType={comfortType}
        />
    );

    const renderKitchen = (comfortType: KitchenComfortType) => (
        <RoomCategoryComfortsItem
            key={comfortType}
            groupType={RoomCategoryComfortsGroupType.Kitchen}
            comfortType={comfortType}
        />
    );

    const sortedKitchenComforts = Object.values(KitchenComfortType)
        .filter(t => kitchenComforts.includes(t));

    const sortedUncategorizedComforts = Object.values(uncategorizedComforts)
        .filter(t => uncategorizedComforts.includes(t));

    const items = [
        ...sortedKitchenComforts.map(renderKitchen),
        ...sortedUncategorizedComforts.map(renderUncategorized)
    ];

    if (!alwaysShown)
        return (
            <RollUp isShown={showAll}>
                <RoomCategoryComfortsGroup type={RoomCategoryComfortsGroupType.Unrecognized}>
                    {items}
                </RoomCategoryComfortsGroup>
            </RollUp>
        );

    const shortList = items.slice(0, SHORT_LIST_COUNT);
    const restList = items.slice(SHORT_LIST_COUNT, items.length);
    const showLink = withShowLink || !!restList.length;

    return (
        <div className={styles.comfortsWithLink}>
            <RoomCategoryComfortsGroup
                type={RoomCategoryComfortsGroupType.Unrecognized}
                title={t("comforts.groups.comforts")}
            >
                {shortList}
                {!!restList.length && (
                    <RollUp isShown={showAll} paddingTop={8}>
                        {restList}
                    </RollUp>
                )}
            </RoomCategoryComfortsGroup>
            {showLink && <RoomCategoryComfortsShowLink/>}
        </div>
    );


};
RoomCategoryComfortsUncategorized.displayName = "RoomCategoryComfortsUncategorized";
export default RoomCategoryComfortsUncategorized;
