import {
    GeneralComfortType,
    WindowViewComfortType,
    BathroomComfortType,
    KitchenComfortType,
    UncategorizedComfortType
} from "@skbkontur/hotel-data/roomCategory";

export enum RoomCategoryComfortsGroupType {
    General = "general",
    Unrecognized = "unrecognized",
    WindowView = "windowView",
    Bathroom = "bathroom",
    Kitchen = "kitchen"
}

export interface IRoomCategoryComfortsContext {
    groupType: RoomCategoryComfortsGroupType;
}

export const RoomCategoryComfortsGroupContext = React.createContext({} as IRoomCategoryComfortsContext);

export const ROOM_CATEGORY_COMFORTS_GROUP_MAP = {
    [RoomCategoryComfortsGroupType.General]: GeneralComfortType,
    [RoomCategoryComfortsGroupType.Unrecognized]: UncategorizedComfortType,
    [RoomCategoryComfortsGroupType.WindowView]: WindowViewComfortType,
    [RoomCategoryComfortsGroupType.Bathroom]: BathroomComfortType,
    [RoomCategoryComfortsGroupType.Kitchen]: KitchenComfortType
};