import {FlippedElement, FlipperHelper} from "@skbkontur/hotel-components/flipper";
import styles from "./BookingLightboxFlipper.scss";
import {BookingLightboxFlipperHelper} from "./BookingLightboxFlipperHelper";
import {BookingLightboxFlipperStaggers} from "./BookingLightboxFlipperStaggers";

interface IBookingLightboxFlipperRateProps {
    renderKey: string;
    rateId: string;
}

const BookingLightboxFlipperRate = (props: React.PropsWithChildren<IBookingLightboxFlipperRateProps>) => {
    const {renderKey, rateId, children} = props;

    return (
        <FlippedElement
            flipId={rateId}
            shouldFlip={BookingLightboxFlipperHelper.shouldFlipAccommodationItem(renderKey)}
            onAppear={FlipperHelper.onAppear(styles.fadeIn400, 400)}
            stagger={BookingLightboxFlipperStaggers.Rate}
            withDiv
            withContext
        >
            {children}
        </FlippedElement>
    );
};
BookingLightboxFlipperRate.displayName = "BookingLightboxFlipperRate";
export default BookingLightboxFlipperRate;
