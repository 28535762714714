import {QuestionCircleIcon16Light} from "@skbkontur/icons/QuestionCircleIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type HelpDotIconTypes = IconTypes.Light16;

interface IHelpDotIconProps extends IconProps {
    type: HelpDotIconTypes;
}

const HelpDotIcon = ({type, ...iconProps}: IHelpDotIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <QuestionCircleIcon16Light {...iconProps}/>;
    }
};
HelpDotIcon.displayName = "HelpDotIcon";
export default HelpDotIcon;
