import {useTranslation} from "@skbkontur/i18n";
import {sortBy} from "lodash";
import {Currency} from "@skbkontur/hotel-data/currency";
import {FlippedResizer} from "@skbkontur/hotel-components/flipper";
import Title from "../../../../components/Title/Title";
import {IAccommodationPrice} from "../../../../data/Accommodation";
import {TranslationNamespaces} from "../../../../constants/TranslationNamespaces";
import BookingLightboxFlipperRate from "../Flipper/BookingLightboxFlipperRate";
import ItemWithBorder from "../../../../components/ItemWithBorder/ItemWithBorder";
import {AccommodationsContext} from "../../../Accommodations/AccommodationsContext";
import {ISelectedAccommodationBooking} from "../../../Accommodations/AccommodationsMaps";
import {BookingLightboxFlipperHelper} from "../Flipper/BookingLightboxFlipperHelper";
import {BookingLightboxStep, BookingLightboxStepsContext} from "../Steps/BookingLightboxStepsContext";
import {bookingLightboxRatesAnalyticsEvents} from "../../../../analytics/bookingLightboxRatesAnalyticsEvents";
import BookingRatesHotelFeatures from "../../../../components/HotelFeatures/BookingRatesHotelFeatures";
import {useSellingHotelFeatures} from "../../../../hooks/useSellingHotelFeatures";
import {BookingFeaturesHelper} from "../../../../helpers/BookingFeaturesHelper";
import styles from "./BookingRates.scss";
import BookingRateItem from "./Item/BookingRateItem";

interface IBookingRatesAccommodationListProps {
    selectedAccommodation: ISelectedAccommodationBooking;
    prices: IAccommodationPrice[];
}

const BookingRatesAccommodationList = (props: IBookingRatesAccommodationListProps) => {
    const {prices, selectedAccommodation} = props;
    const {accommodation, params} = selectedAccommodation;
    const {renderKey, rateId, bookingFeatures, adultsCount, childrenCount} = accommodation;
    const {roomCategoryId, mainOccupancy, additionalOccupancy, index} = params;

    const hotelFeatures = useSellingHotelFeatures();
    const {t} = useTranslation(TranslationNamespaces.BookingModuleBooking);
    const {updateAccommodation} = React.useContext(AccommodationsContext);
    const {step, goToStep} = React.useContext(BookingLightboxStepsContext);

    const isSelected = !!rateId;
    const pricesToRender = isSelected
        ? [prices.find(p => p.rateId === rateId)]
        : sortBy<IAccommodationPrice>(prices, (p: IAccommodationPrice) => Currency.getFloatValue(p.price));

    const chooseRate = (rateId: string) => {
        // in case of rateId === null there is "change rate" happening, not selecting
        if (rateId !== null) {
            bookingLightboxRatesAnalyticsEvents.trackSelectRate(pricesToRender.length);
            updateAccommodation({
                roomCategoryId,
                mainOccupancy,
                additionalOccupancy,
                index,
                accommodation: {...accommodation, rateId}
            });
        }
        if (rateId === null) {
            updateAccommodation({
                roomCategoryId,
                mainOccupancy,
                additionalOccupancy,
                index,
                accommodation: {...accommodation, rateId, bookingFeatures: {}},
            });
            if (step === BookingLightboxStep.FinishOrder) {
                goToStep(BookingLightboxStep.SelectRates, {roomCategoryId});
            }
        }
    };

    const updateHotelFeature = (hotelFeatureId: string, newCount: number) => {
        const bookingFeatures = BookingFeaturesHelper.filter(accommodation.bookingFeatures, hotelFeatureId, newCount);

        updateAccommodation({
            ...params,
            accommodation: {
                ...accommodation,
                bookingFeatures,
            },
        });
    };

    const guestsCount = adultsCount + (childrenCount || 0);

    return (
        <FlippedResizer flipId="Rates" shouldFlip={BookingLightboxFlipperHelper.shouldFlipAccommodationItem(renderKey)}>
            {!isSelected && (
                <div className={styles.title}>
                    <Title small>{t("rates.caption")}</Title>
                </div>
            )}
            {pricesToRender.map((item, index) => (
                <ItemWithBorder key={index} withoutBorder={index === pricesToRender.length - 1}>
                    <BookingLightboxFlipperRate rateId={item.rateId} renderKey={renderKey}>
                        <BookingRateItem
                            accommodation={accommodation}
                            price={item}
                            single={prices.length === 1}
                            onSelectRate={chooseRate}
                        />
                    </BookingLightboxFlipperRate>
                    {!!hotelFeatures.length && isSelected && (
                        <BookingLightboxFlipperRate rateId={`hotelFeatures_${item.rateId}`} renderKey={renderKey}>
                            <BookingRatesHotelFeatures
                                title={t("hotelFeatures.title")}
                                sellingHotelFeatures={hotelFeatures}
                                rateId={item.rateId}
                                hotelFeaturesMap={bookingFeatures}
                                guestsCount={guestsCount}
                                updateHotelFeature={updateHotelFeature}
                            />
                        </BookingLightboxFlipperRate>
                    )}
                </ItemWithBorder>
            ))}
        </FlippedResizer>
    );
};
BookingRatesAccommodationList.displayName = "BookingRatesAccommodationList";
export default BookingRatesAccommodationList;
