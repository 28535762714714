import {RoomCategoryComforts} from "@skbkontur/hotel-components/roomCategory";
import RoomCategoryParameters from "@skbkontur/hotel-components/roomCategory/parameters/RoomCategoryParameters";
import {ModifyImageUrlContext} from "@skbkontur/hotel-components/modifyImageUrl";
import {useRoomCategory} from "../../../hooks/useRoomCategory";
import {Compare} from "../../../helpers/CompareHelper";
import SliderWithGallery from "../../SliderWithGallery/SliderWithGallery";
import RoomCategoryEmpty from "../../../images/empty/RoomCategoryEmpty.png";
import EntityCardContainer from "../EntityCardContainer";
import EntityCardTitle from "../EntityCardTitle";
import EntityCardSlider from "../EntityCardSlider";
import EntityCardDescription from "../EntityCardDescription";
import EntityCardContent from "../EntityCardContent";
import EntityCardRight from "../EntityCardRight";

interface IRoomCategoryCardProps {
    id: string;
    title?: string;
    autoPlaySlider?: boolean;
    showAllComforts?: boolean;
}

const RoomCategoryCard = (props: React.PropsWithChildren<IRoomCategoryCardProps>) => {
    const {id, title, children, autoPlaySlider, showAllComforts} = props;


    const {modifyUrl} = React.useContext(ModifyImageUrlContext);
    const roomCategory = useRoomCategory(id);

    const {name, description, imageMetas} = roomCategory;

    return (
        <EntityCardContainer>
            <EntityCardSlider>
                <SliderWithGallery
                    autoPlay={autoPlaySlider}
                    imageMetas={[...imageMetas].sort(Compare.byOrder)}
                    emptyImage={modifyUrl(RoomCategoryEmpty)}
                />
            </EntityCardSlider>
            <EntityCardContent>
                <EntityCardTitle title={title || name}/>
                <RoomCategoryParameters roomCategory={roomCategory} hideComfortsLabel/>
                {description && <EntityCardDescription description={description}/>}
                <RoomCategoryComforts roomCategory={roomCategory} showMoreMode={showAllComforts}/>
            </EntityCardContent>
            <EntityCardRight>
                {children}
            </EntityCardRight>
        </EntityCardContainer>
    );
};
RoomCategoryCard.displayName = "RoomCategoryCard";
export default React.memo(RoomCategoryCard);
