import {MinusIcon16Regular} from "@skbkontur/icons/MinusIcon16Regular";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type MinusIconTypes = IconTypes.Regular16;

interface IMinusIconProps extends IconProps {
    type: MinusIconTypes;
}

const MinusIcon = ({type, ...iconProps}: IMinusIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <MinusIcon16Regular {...iconProps}/>;
    }
};
MinusIcon.displayName = "MinusIcon";
export default MinusIcon;
