import {EyeOpenIcon16Light} from "@skbkontur/icons/EyeOpenIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type EyeIconTypes = IconTypes.Light16;

interface IEyeIconProps extends IconProps {
    type: EyeIconTypes;
}

const EyeIcon = ({type, ...iconProps}: IEyeIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <EyeOpenIcon16Light {...iconProps}/>;
    }
};
EyeIcon.displayName = "EyeIcon";
export default EyeIcon;
