import {LogoWhatsappIcon16Regular} from "@skbkontur/icons/LogoWhatsappIcon16Regular";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type WhatsUpIconTypes = IconTypes.Regular16;

interface IWhatsAppIconProps extends IconProps {
    type: WhatsUpIconTypes;
}

const WhatsAppIcon = ({type, ...iconProps}: IWhatsAppIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <LogoWhatsappIcon16Regular {...iconProps}/>;
    }
};
WhatsAppIcon.displayName = "WhatsAppIcon";
export default WhatsAppIcon;
