import {LogoVkIcon16Solid} from "@skbkontur/icons/LogoVkIcon16Solid";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type VkIconTypes = IconTypes.Solid16;

interface IVkIconProps extends IconProps {
    type: VkIconTypes;
}

const VkIcon = ({type, ...iconProps}: IVkIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <LogoVkIcon16Solid {...iconProps}/>;
    }
};
VkIcon.displayName = "VkIcon";
export default VkIcon;
