import {GeneralComfortType} from "@skbkontur/hotel-data/roomCategory";
import {Nullable} from "@skbkontur/react-ui/typings/utility-types";
import {Label} from "../../../label";
import {RoomCategoryComfortsGroupType} from "./RoomCategoryComfortsGroupContext";
import RoomCategoryComfortsIcon from "./RoomCategoryComfortsIcon";

interface IRoomCategoryComfortsLabelProps {
    generalComforts: Nullable<GeneralComfortType[]>;
}

const RoomCategoryComfortsLabel = (props: IRoomCategoryComfortsLabelProps) => {
    const {generalComforts} = props;

    if (!generalComforts?.length)
        return null;

    return (
        <Label data-tid="ComfortsLabel">
            {generalComforts.map(comfortType => (
                <RoomCategoryComfortsIcon
                    key={comfortType}
                    groupType={RoomCategoryComfortsGroupType.General}
                    comfortType={comfortType}
                />
            ))}
        </Label>
    );
};
RoomCategoryComfortsLabel.displayName = "RoomCategoryComfortsLabel";
export default RoomCategoryComfortsLabel;