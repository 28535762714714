import {LogoTelegramIcon16Regular} from "@skbkontur/icons/LogoTelegramIcon16Regular";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type CardIconTypes = IconTypes.Regular16;

interface ITelegramIconProps extends IconProps {
    type: CardIconTypes;
}

const TelegramIcon = ({type, ...iconProps}: ITelegramIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <LogoTelegramIcon16Regular {...iconProps}/>;
    }
};
TelegramIcon.displayName = "TelegramIcon";
export default TelegramIcon;
