import {CheckAIcon16Solid} from "@skbkontur/icons/CheckAIcon16Solid";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {CheckAIcon16Light} from "@skbkontur/icons/CheckAIcon16Light";
import {IconTypes} from "./Icon";

type OkIconTypes = IconTypes.Solid16 | IconTypes.Light16;

interface IOkIconProps extends IconProps {
    type: OkIconTypes;
}

const OkIcon = ({type, ...iconProps}: IOkIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <CheckAIcon16Solid {...iconProps}/>;
        case IconTypes.Light16:
            return <CheckAIcon16Light {...iconProps}/>;
    }
};
OkIcon.displayName = "OkIcon";
export default OkIcon;
