import {Gapped} from "@skbkontur/react-ui";
import {useFlipperMountStagger, FlippedElement, FlipperHelper} from "@skbkontur/hotel-components/flipper";
import {IRoomCategoryAccommodation} from "../../../../../data/Accommodation";
import {BookingLightboxFlipperStaggers} from "../../Flipper/BookingLightboxFlipperStaggers";
import {useShouldFlipStepsChange} from "../../Flipper/useShouldFlipStepsChange";
import BookingAccommodationsItem from "./BookingAccommodationsItem";
import styles from "./BookingAccommodationsList.scss";

interface IBookingAccommodationsListProps {
    accommodation: IRoomCategoryAccommodation;
}

const BookingAccommodationsList = (props: IBookingAccommodationsListProps) => {
    const {accommodation} = props;
    const {roomCategoryId, accommodations} = accommodation;

    const stagger = useFlipperMountStagger(BookingLightboxFlipperStaggers.Occupancy);
    const shouldFlip = useShouldFlipStepsChange();

    return (
        <Gapped gap={20} vertical>
            {accommodations.map(item => (
                <FlippedElement
                    key={`${item.mainOccupancy}_${item.additionalOccupancy}`}
                    flipId={`${item.mainOccupancy}_${item.additionalOccupancy}`}
                    shouldFlip={shouldFlip}
                    onAppear={FlipperHelper.onAppear(styles.fadeIn, 300)}
                    withContext
                    stagger={stagger}
                >
                    <BookingAccommodationsItem roomCategoryId={roomCategoryId} accommodation={item}/>
                </FlippedElement>
            ))}
        </Gapped>
    );
};
BookingAccommodationsList.displayName = "BookingAccommodationsList";
export default React.memo(BookingAccommodationsList);
