import {ThemeIn} from "@skbkontur/react-ui/cjs/lib/theming/Theme";
import {ButtonUse} from "@skbkontur/react-ui/cjs/components/Button/Button";
import {Nullable} from "@skbkontur/react-ui/typings/utility-types";
import {merge} from "lodash";
import Color from "color";
import {SLIDER_THEME_PREFIX} from "@skbkontur/hotel-components/slider/SliderTheme";
import {StringUtils} from "@skbkontur/hotel-utils";
import {ROOM_CATEGORY_LABEL_THEME_PREFIX} from "@skbkontur/hotel-components/roomCategory";
import {KnownActualWidgetSettings} from "../settings/WidgetInstanceSettings";
import {
    BM_DEFAULT_REACT_UI_THEME,
    BM_THEME_BUTTONS_MAP,
    BM_THEME_CSS_PREFIX,
    BM_THEME_REACT_UI_SIZE_PROPS
} from "./ThemeConstants";
import {IThemeButton, IThemeButtons, IThemeCommon} from "./ThemeCommon";
import {IThemeWidgetCommon} from "./ThemeWidgets";
import {ITheme} from "./Theme";

export class ThemeHelper {
    static getWidgetCSSPrefix(keyPath: string[]): string {
        switch (true) {
            case keyPath.includes(SLIDER_THEME_PREFIX):
            case keyPath.includes(ROOM_CATEGORY_LABEL_THEME_PREFIX):
                return null;
            default:
                return BM_THEME_CSS_PREFIX;
        }
    }

    static getWidgetFinalTheme<TSettings extends KnownActualWidgetSettings>(
        theme: Nullable<ITheme>,
        widgetTheme: KnownActualWidgetSettings["theme"],
        type: KnownActualWidgetSettings["type"]
    ): TSettings["theme"] {
        const {[type]: widgetRootTheme} = theme || {};

        const {common: _instanceCommon, ...instanceTheme} = widgetTheme || {};
        const {common: _widgetCommon, ...rootTheme} = widgetRootTheme || {};

        const finalWidgetTheme = {};
        merge(finalWidgetTheme, rootTheme, instanceTheme);

        return finalWidgetTheme;
    }

    static getReactUITheme(
        theme: Nullable<ITheme>,
        widgetTheme: KnownActualWidgetSettings["theme"],
        type: KnownActualWidgetSettings["type"]
    ): ThemeIn {
        const {common, [type]: widgetRootTheme} = theme || {};

        const {common: widgetRootCommon} = widgetRootTheme || {};
        const {common: widgetInstanceCommon} = widgetTheme || {};

        const mergedTheme = {
            ...this.getThemeCommonReactUITheme(common),
            ...this.getWidgetThemeCommonReactUITheme(widgetRootCommon),
            ...this.getWidgetThemeCommonReactUITheme(widgetInstanceCommon)
        };

        return this.normalizeReactUITheme(mergedTheme);
    }

    static getCommonReactUITheme(theme: Nullable<ITheme>): ThemeIn {
        const {common} = theme || {};
        const reactUITheme = this.getThemeCommonReactUITheme(common);
        return this.normalizeReactUITheme(reactUITheme);
    }

    static validatePeriodPicker(separatorText: string, fromText: string, toText: string) {
        if (separatorText && (fromText || toText)) {
            // eslint-disable-next-line no-console
            console.error(
                "Theme error: PeriodPicker property \"separatorText\" should be used without \"fromText\" and \"toText\". Only \"separatorText\" will be applied."
            );
        } else if ((fromText && !toText) || (!fromText && toText)) {
            // eslint-disable-next-line no-console
            console.error(`Theme error: Both properties "fromText"="${fromText}" and "toText"="${toText}" should be provided.`);
        }
    }

    private static normalizeReactUITheme(rawThem: object & ThemeIn): ThemeIn {
        const theme = {} as ThemeIn;
        Object.keys(rawThem).forEach((key: string) => {
            const value = rawThem[key];
            if (BM_THEME_REACT_UI_SIZE_PROPS.includes(key)) {
                theme[`${key}Small`] = value;
                theme[`${key}Medium`] = value;
                theme[`${key}Large`] = value;
            } else if (ThemeHelper.isExistInReactUITheme(key)) {
                theme[key] = value;
            }
        });
        return theme;
    }

    static isExistInReactUITheme = (key: string) => BM_DEFAULT_REACT_UI_THEME[key] !== undefined;

    private static getThemeCommonReactUITheme(common: IThemeCommon): ThemeIn {
        const {base, buttons, controls, modal, tooltip} = common || {};
        return {
            ...base,
            ...this.getButtonsThemeReactUITheme(buttons),
            ...controls,
            ...modal,
            ...tooltip
        };
    }

    private static getWidgetThemeCommonReactUITheme(widgetCommon: IThemeWidgetCommon): ThemeIn {
        const {base, buttons, controls} = widgetCommon || {};
        return {
            ...base,
            ...this.getButtonsThemeReactUITheme(buttons),
            ...controls
        };
    }

    private static getButtonsThemeReactUITheme(buttonsSettings: IThemeButtons | undefined): ThemeIn {
        return Object.keys(buttonsSettings || {}).reduce((theme: ThemeIn, buttonType) => {
            const typedButtonType = buttonType as keyof IThemeButtons;
            const buttonUse = BM_THEME_BUTTONS_MAP[typedButtonType];
            const buttonTypeSettings = buttonsSettings[typedButtonType] || {};
            const normalizedSettings = this.colorizeButtonSettings(buttonTypeSettings);
            return {
                ...theme,
                ...this.getButtonTypeReactUITheme(buttonUse, normalizedSettings)
            };
        }, {} as ThemeIn);
    }

    private static getButtonTypeReactUITheme(reactUIButtonUse: ButtonUse, buttonTypeSettings: IThemeButton): ThemeIn {
        const useKey = StringUtils.capitalizeFirstLetter(reactUIButtonUse);
        return Object.keys(buttonTypeSettings || {}).reduce((theme: ThemeIn, buttonThemeKey: string) => {
            const themeKey = StringUtils.capitalizeFirstLetter(buttonThemeKey);
            return {
                ...theme,
                [`btn${useKey}${themeKey}`]: buttonTypeSettings[buttonThemeKey as keyof IThemeButton]
            };
        }, {} as ThemeIn);
    }

    private static colorizeButtonSettings(settings: IThemeButton) {
        const {...safetySettings} = settings;
        const {bg, hoverBg, activeBg} = safetySettings;

        if (bg && !hoverBg) safetySettings.hoverBg = this.oppositeTone(bg, 0.1);
        if (bg && !activeBg) safetySettings.activeBg = this.darkenTone(bg, 0.2);

        return safetySettings;
    }

    private static oppositeTone(base: string, tone: number) {
        const baseColor = Color(base);
        const toneColor = baseColor.isLight() ? baseColor.darken(tone) : baseColor.lighten(tone);
        return toneColor.string();
    }

    private static darkenTone(base: string, tone: number) {
        return Color(base).darken(tone).string();
    }
}

