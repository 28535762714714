import {Flipper} from "react-flip-toolkit";
import {FlippedResizer} from "@skbkontur/hotel-components/flipper";
import {Button} from "@skbkontur/react-ui";
import {StringUtils} from "@skbkontur/hotel-utils";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../constants/TranslationNamespaces";
import styles from "./EntityCard.scss";

interface IEntityCardDescriptionProps {
    description: string;
}

const EntityCardDescription = (props: IEntityCardDescriptionProps) => {
    const {description} = props;

    const {tcn} = useTranslation(TranslationNamespaces.Common);

    const [showMore, setShowMore] = React.useState(false);
    const toggleShowMore = () => setShowMore(isMore => !isMore);

    const ellipsisText = StringUtils.ellipsis(description, 180);
    const descriptionToShow = showMore ? description : ellipsisText;

    return (
        <div data-tid="Description">
            <Flipper flipKey={descriptionToShow}>
                <FlippedResizer className={styles.description} flipId="description">
                    {descriptionToShow}
                    {ellipsisText !== description && (
                        <Button
                            data-tid="ShowMoreLink"
                            className={styles.showMoreLink}
                            use="link"
                            onClick={toggleShowMore}
                        >
                            {showMore ? tcn("buttons.less") : tcn("buttons.more")}
                        </Button>
                    )}
                </FlippedResizer>
            </Flipper>
        </div>
    );
};
EntityCardDescription.displayName = "EntityCardDescription";
export default EntityCardDescription;
