import {Flipped} from "react-flip-toolkit";
import {useFlipperMountStagger, FlippedElement, FlipperHelper} from "@skbkontur/hotel-components/flipper";
import ItemWithBorder from "../../../../components/ItemWithBorder/ItemWithBorder";
import {HourlyBookingStaggersFlipper} from "./HourlyBookingStaggersFlipper";
import styles from "./HourlyBookingLightboxFlipper.scss";
import {useHourlyShouldFlipStepsChange} from "./useHourlyShouldFlipStepsChange";

interface IHourlyBookingRatesFlipperProps {
    renderKey: string;
    isLast: boolean;
}

const HourlyBookingRatesFlipper = (
    props: React.PropsWithChildren<IHourlyBookingRatesFlipperProps>
) => {
    const {renderKey, children, isLast} = props;

    const stagger = useFlipperMountStagger(HourlyBookingStaggersFlipper.Booking);
    const shouldFlip = useHourlyShouldFlipStepsChange();

    return (
        <FlippedElement
            flipId={renderKey}
            shouldFlip={shouldFlip}
            onAppear={FlipperHelper.onAppear(styles.fadeIn500, 500)}
            stagger={stagger}
            withContext
            withDelay
            withDiv
        >
            <ItemWithBorder withoutBorder={isLast}>
                <Flipped inverseFlipId={renderKey}>
                    <div>{children}</div>
                </Flipped>
            </ItemWithBorder>
        </FlippedElement>
    );
};
HourlyBookingRatesFlipper.displayName = "HourlyBookingRatesFlipper";
export default React.memo(HourlyBookingRatesFlipper);
