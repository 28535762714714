import {IRoomCategory} from "@skbkontur/hotel-data/roomCategory";
import {RoomCategoryLabel, RoomCategoryComfortsLabel, RoomCategoryLabelRooms} from "../index";
import styles from "./RoomCategoryParameters.scss";

interface IRoomCategoryParametersProps {
    roomCategory: IRoomCategory;
    hideComfortsLabel?: boolean;
}

const RoomCategoryParameters = (props: IRoomCategoryParametersProps) => {
    const {roomCategory, hideComfortsLabel} = props;
    const {comforts} = roomCategory;
    const {generalComforts} = comforts || {};

    return (
        <div data-tid="RoomCategoryParameters" className={styles.root}>
            <div className={styles.labels}>
                <RoomCategoryLabel category={roomCategory}/>
                {!hideComfortsLabel && <RoomCategoryComfortsLabel generalComforts={generalComforts}/>}
            </div>
            <RoomCategoryLabelRooms category={roomCategory}/>
        </div>
    );
};
RoomCategoryParameters.displayName = "RoomCategoryParameters";
export default RoomCategoryParameters;