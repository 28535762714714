import {GeneralComfortType} from "@skbkontur/hotel-data/roomCategory";
import RoomCategoryComfortsGroup from "../components/RoomCategoryComfortsGroup";
import {RoomCategoryComfortsGroupType} from "../components/RoomCategoryComfortsGroupContext";
import RoomCategoryComfortsItem from "../components/RoomCategoryComfortsItem";
import RoomCategoryComfortsShowLink from "../components/RoomCategoryComfortsShowLink";
import styles from "../RoomCategoryComforts.scss";

interface IRoomCategoryComfortsGeneralProps {
    comforts: GeneralComfortType[];
    withShowLink?: boolean;
}

const RoomCategoryComfortsGeneral = (props: IRoomCategoryComfortsGeneralProps) => {
    const {comforts, withShowLink} = props;

    const sortedComforts = Object.values(GeneralComfortType).filter(t => comforts.includes(t));

    return (
        <div className={styles.comfortsWithLink}>
            <RoomCategoryComfortsGroup type={RoomCategoryComfortsGroupType.General}>
                {sortedComforts.map(comfortType => (
                    <RoomCategoryComfortsItem
                        key={comfortType}
                        groupType={RoomCategoryComfortsGroupType.General}
                        comfortType={comfortType}
                    />
                ))}
            </RoomCategoryComfortsGroup>
            {withShowLink && <RoomCategoryComfortsShowLink/>}
        </div>
    );
};
RoomCategoryComfortsGeneral.displayName = "RoomCategoryComfortsGeneral";
export default RoomCategoryComfortsGeneral;
