// TODO оставить один из вариантов после того, как поменяются модели на бекенде

export interface IWithDeletedOrIsDeleted extends IWithDeleted, IWithIsDeleted {
}

export interface IWithDeleted {
    deleted?: boolean;
}

export interface IWithIsDeleted {
    isDeleted?: boolean;
}

export interface IWithBanned {
    banned?: boolean;
}

export interface IWithDataTid {
    "data-tid"?: string;
}

export const getNotDeletedItems = <TItem extends IWithDeletedOrIsDeleted>(items: TItem[]): TItem[] => (
    (items || []).filter(item => !item.deleted && !item.isDeleted)
);

export const getNotBannedItems = <TItem extends IWithBanned>(items: TItem[]): TItem[] => (
    (items || []).filter(item => !item.banned)
);

export const getNotDeletedAndBannedItems = <TItem extends IWithBanned & IWithDeletedOrIsDeleted>(items: TItem[]): TItem[] => (
    getNotBannedItems(getNotDeletedItems(items))
);

export interface IWithId {
    id?: string;
}

export interface IWithGuestId {
    guestId?: string;
}

export const getItemById = <TItem extends IWithId>(items: TItem[], id?: string): TItem => (
    id ? (items || []).find(r => r.id === id) : null
);

export const getItemByGuestId = <TItem extends IWithGuestId>(items: TItem[], guestId?: string): TItem => (
    guestId ? (items || []).find(r => r.guestId === guestId) : null
);

export const getItemIndexById = <TItem extends IWithId>(items: TItem[], id?: string): number => (
    id ? (items || []).findIndex(r => r.id === id) : null
);

export interface IWithOrder {
    order?: number;
}

export interface IWithClassName {
    className?: string;
}