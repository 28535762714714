import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import {BathroomType} from "@skbkontur/hotel-data/roomCategory";

interface IRoomCategoryBathroomTypeProps {
    bathroomType: BathroomType;
}

const RoomCategoryBathroomType = (props: IRoomCategoryBathroomTypeProps) => {
    const {bathroomType} = props;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    return (
        <div data-tid="BathroomType">
            {t(`bathroomTypesTags.${bathroomType}`)}
        </div>
    );
};
RoomCategoryBathroomType.displayName = "RoomCategoryBathroomType";
export default RoomCategoryBathroomType;