import {FoodWareForkKnifeIcon16Light} from "@skbkontur/icons/FoodWareForkKnifeIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type KitchenIconTypes = IconTypes.Light16;

interface IKitchenIconProps extends IconProps {
    type: KitchenIconTypes;
}

const KitchenIcon = ({type, ...iconProps}: IKitchenIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <FoodWareForkKnifeIcon16Light {...iconProps}/>;
    }
};
KitchenIcon.displayName = "KitchenIcon";
export default KitchenIcon;
