import {KnownRoomCategoryComforts, GeneralComfortType} from "@skbkontur/hotel-data/roomCategory/RoomCategoryComforts";
import {IconTypes, PhoneIcon, SafeIcon, WifiIcon, TvIcon, OkIcon} from "../../../icon";
import SnowIcon from "../../../icon/ShowIcon";
import {RoomCategoryComfortsGroupType} from "./RoomCategoryComfortsGroupContext";

interface IRoomCategoryComfortsIconProps {
    groupType: RoomCategoryComfortsGroupType;
    comfortType: KnownRoomCategoryComforts;
}

const RoomCategoryComfortsIcon = (props: IRoomCategoryComfortsIconProps) => {
    const {comfortType, groupType} = props;

    const getIcon = (): React.ReactNode => {
        const isGeneral = groupType === RoomCategoryComfortsGroupType.General;
        switch (true) {
            case isGeneral && comfortType === GeneralComfortType.Conditioner:
                return <SnowIcon type={IconTypes.Light16}/>;
            case isGeneral && comfortType === GeneralComfortType.WiFi:
                return <WifiIcon type={IconTypes.Light16}/>;
            case isGeneral && comfortType === GeneralComfortType.Tv:
                return <TvIcon type={IconTypes.Light16}/>;
            case isGeneral && comfortType === GeneralComfortType.Telephone:
                return <PhoneIcon type={IconTypes.Light16}/>;
            case isGeneral && comfortType === GeneralComfortType.Safe:
                return <SafeIcon type={IconTypes.Light16}/>;
            default:
                return <OkIcon type={IconTypes.Light16}/>;
        }
    };

    return getIcon();
};
RoomCategoryComfortsIcon.displayName = "RoomCategoryComfortsIcon";
export default RoomCategoryComfortsIcon;