import {ModifyImageUrlContext} from "../modifyImageUrl";
import {svgIconsByTypeMap, SvgIconType} from "./SvgIconType";

export interface ISvgIconProps extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "src"> {
    type: SvgIconType;
}

const SvgIcon = (props: ISvgIconProps) => {
    const {type, className = "", ...imgProps} = props;

    const {modifyUrl} = React.useContext(ModifyImageUrlContext);

    const imageUrl = modifyUrl ? modifyUrl(svgIconsByTypeMap[type]) : svgIconsByTypeMap[type];

    return (
        <img
            className={className}
            style={{display: "block"}}
            {...imgProps}
            src={imageUrl}
        />
    );
};
SvgIcon.displayName = "SvgIcon";
export default SvgIcon;
