export enum IconTypes {
    Light16 = "Light16",
    Regular16 = "Regular16",
    Solid16 = "Solid16",
    Light20 = "Light20",
    Regular20 = "Regular20",
    Solid20 = "Solid20",
    Light24 = "Light24",
    Regular24 = "Regular24",
    Solid24 = "Solid24",
    Light32 = "Light32",
    Regular32 = "Regular32",
    Solid32 = "Solid32",
    Light64 = "Light64",
    Regular64 = "Regular64",
    Solid64 = "Solid64"
}
