import {IImageMeta, IImageSource} from "../image";
import {IWithRenderKey} from "../renderKey";
import {IWithOrder} from "../compositions";
import {IRoomCategoryComforts} from "./RoomCategoryComforts";

export interface IRoomCategory extends IWithOrder {
    id: string;
    name: string;
    description: string;
    roomCategoryType: RoomCategoryType;
    area?: number;
    placesMin?: number;
    placesMax?: number;
    additionalPlaces?: number;
    imageMetas?: IImageMeta[];
    imageSources?: IImageSource[];
    rooms?: IRoomStructure[];
    hasKitchen?: boolean;
    bathroomType?: BathroomType;
    deleted?: boolean;
    comforts?: IRoomCategoryComforts;

    // Front field
    roomsCount?: number;
}

export enum RoomCategoryType {
    FirstCategory = "FirstCategory",
    SecondCategory = "SecondCategory",
    ThirdCategory = "ThirdCategory",
    FourthCategory = "FourthCategory",
    FifthCategory = "FifthCategory",
    JuniorSuite = "JuniorSuite",
    Studio = "Studio",
    Lux = "Lux",
    Apartment = "Apartment",
    Suite = "Suite",
    Other = "Other",
    Hostel = "Hostel"
}

export interface IRoomStructure extends IWithRenderKey {
    type: RoomType;
    beds: IBedStructure<BedType>[];
    placesCount: number;
    additionalBeds: IBedStructure<AdditionalBedType>[];
    additionalPlacesCount: number;
}

export interface IBedStructure<T extends KnownBedTypes> extends IWithRenderKey {
    type: T;
}

export enum RoomType {
    LivingOrBedroom = "LivingOrBedroom",
    Living = "Living",
    LivingAndDining = "LivingAndDining",
    Cabinet = "Cabinet"
}

export enum BathroomType {
    Full = "Full",
    FullAndGuest = "FullAndGuest",
    FullForAFewRooms = "FullForAFewRooms",
    FullPerFloorAndRoomSink = "FullPerFloorAndRoomSink",
    FullPerFloor = "FullPerFloor"
}

export enum BedType {
    Single = "Single",
    Double = "Double",
    DoubleKing = "DoubleKing",
    DoubleSplit = "DoubleSplit",
    Bunk = "Bunk"
}

export enum AdditionalBedType {
    DoubleSofa = "DoubleSofa",
    Sofa = "Sofa",
    Chair = "Chair",
    Baby = "Baby",
    Cot = "Cot"
}

export type KnownBedTypes = BedType | AdditionalBedType;