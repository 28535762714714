import {ArrowCDownIcon16Light} from "@skbkontur/icons/ArrowCDownIcon16Light";
import {ArrowCDownIcon16Regular} from "@skbkontur/icons/ArrowCDownIcon16Regular";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type ArrowCurveDownIconTypes = IconTypes.Light16 | IconTypes.Regular16;

interface IArrowCurveDownIconProps extends IconProps {
    type: ArrowCurveDownIconTypes;
}

const ArrowCurveDownIcon = ({type, ...iconProps}: IArrowCurveDownIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <ArrowCDownIcon16Light {...iconProps}/>;
        case IconTypes.Regular16:
            return <ArrowCDownIcon16Regular {...iconProps}/>;
    }
};
ArrowCurveDownIcon.displayName = "ArrowCurveDownIcon";
export default ArrowCurveDownIcon;
