import {DateCompare, DateFormat} from "@skbkontur/hotel-date";
import {IHourlyObjectsItemAvailableTime, IHourlyObjectsListAvailableTimeState} from "../HourlyObjectsListAvailableTime";
import {ONE_HOUR_IN_MS} from "./useHourlyAvailabilities";

interface IUseHourlyValidationAvailableTimeProps {
    state: IHourlyObjectsListAvailableTimeState;
    fromItems: IHourlyObjectsItemAvailableTime[];
    toItems: IHourlyObjectsItemAvailableTime[];
    setState: React.Dispatch<React.SetStateAction<IHourlyObjectsListAvailableTimeState>>;
}

export const useHourlyValidationAvailableTime = (props: IUseHourlyValidationAvailableTimeProps) => {
    const {state, toItems, fromItems, setState} = props;
    const {from, to, isError} = state;

    React.useEffect(() => {
        if (!from || !to || !fromItems.length) {
            return;
        }

        const isCorrectInterval = DateCompare.isBefore({
            firstDate: from.timestamp,
            secondDate: to.timestamp,
            format: DateFormat.UnixMsTimestamp
        });
        const isExistTo = toItems.find((el) => el.timestamp === to.timestamp);

        if (!isCorrectInterval || !isExistTo) {
            setState(prev => ({...prev, isError: true}));
            return;
        }

        const timestampFrom = from.timestamp;
        const timestampTo = to.timestamp;

        for (const item of fromItems) {
            const timestampItem = item.timestamp;

            if (timestampFrom > timestampItem) {
                continue;
            }

            if (timestampItem + ONE_HOUR_IN_MS === timestampTo) {
                if (isError) {
                    setState(prev => ({...prev, isError: false}));
                }
                break;
            }

            if (item.isUnavailable) {
                setState(prev => ({...prev, isError: true}));
                break;
            }
        }
    }, [from, fromItems, to, toItems, isError]);
};
