import {BuildingHomeIcon16Light} from "@skbkontur/icons/BuildingHomeIcon16Light";
import {BuildingHomeIcon20Regular} from "@skbkontur/icons/BuildingHomeIcon20Regular";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type BuildingHomeIconTypes = IconTypes.Light16 | IconTypes.Regular20;

interface IBuildingHomeIconProps extends IconProps {
    type: BuildingHomeIconTypes;
}

const BuildingHomeIcon = ({type, ...iconProps}: IBuildingHomeIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <BuildingHomeIcon16Light {...iconProps}/>;
        case IconTypes.Regular20:
            return <BuildingHomeIcon20Regular {...iconProps}/>;
    }
};
BuildingHomeIcon.displayName = "BuildingHomeIcon";
export default BuildingHomeIcon;
