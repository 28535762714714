import {DateCalculate, DateFormat, DateHelper} from "@skbkontur/hotel-date";
import {ConfigType} from "dayjs";
import {Currency, ICurrency} from "@skbkontur/hotel-data/currency";
import {createMapBy} from "@skbkontur/hotel-utils";
import {IFlippedResizerScrollTopAnimationSettings} from "@skbkontur/hotel-components/flipper";
import {DeviceMode, DeviceSize} from "../../Device/DeviceContext";
import {IHourlyAccommodation, IHourlyAccommodationPrice, IHourlyBookingAccommodation} from "../../../data/HourlyBooking";
import {IBaseSearchParams, IHourlyObjectSearchParams} from "../../../data/SearchParams";
import {HotelFeaturesMap} from "../../../data/Booking";
import {IBookingOrderState} from "../../../data/BookingOrder";
import {IFeatureWithCount} from "./Order/useSelectedHotelFeatures";
import {
    HourlyBookingLightboxStep,
    IHourlyBookingLightboxStepsParams,
} from "./Steps/HourlyBookingLightboxStepsContext";

/* This constants linked with parameters in BookingLightbox.scss */
const LIGHTBOX_MARGINS = 60;
const LIGHTBOX_MARGINS_MOBILE_LARGE = 40;
const LIGHTBOX_MARGINS_MOBILE = 20;
const LIGHTBOX_MAX_WIDTH = 970;

export const HOURLY_BOOKING_LIGHTBOX_CLASSNAME = "HourlyBookingLightbox";

interface IHourlyTotalPrice {
    totalSum: ICurrency;
    featuresCount: number;
    totalHours: number;
}

interface IHourlyBookingTotalInfo {
    hotelFeatures: IFeatureWithCount[];
    selectedAccommodation: IHourlyBookingAccommodation;
    accommodationPrices: IHourlyAccommodationPrice[];
    params: IHourlyObjectSearchParams;
}

export class HourlyBookingLightboxHelper {
    static getWidth = (deviceMode: DeviceMode) => {
        const width = window.innerWidth;
        switch (deviceMode) {
            case DeviceMode.MobileLarge:
                return Math.min(width - LIGHTBOX_MARGINS_MOBILE_LARGE, LIGHTBOX_MAX_WIDTH);
            case DeviceMode.Mobile:
                return Math.min(DeviceSize.Mobile - LIGHTBOX_MARGINS_MOBILE, LIGHTBOX_MAX_WIDTH);
            default:
                return Math.min(width - LIGHTBOX_MARGINS, LIGHTBOX_MAX_WIDTH);
        }
    };

    static getTotalPrepayment = (hourlyRateId: string, accommodationPrices: IHourlyAccommodationPrice[]): ICurrency => (
        accommodationPrices.find((el) => el.rateId === hourlyRateId).prepaymentSum || Currency.zero()
    );

    static getScrollElement = (): HTMLElement => (
        document.querySelector(`.${HOURLY_BOOKING_LIGHTBOX_CLASSNAME} [data-tid="modal-container"]`)
    );

    static isScrollWaitHeight = (step: HourlyBookingLightboxStep) => (
        step === HourlyBookingLightboxStep.FinishOrder
    );

    static getScrollSettings = (
        step: HourlyBookingLightboxStep,
        params: IHourlyBookingLightboxStepsParams
    ): IFlippedResizerScrollTopAnimationSettings => {
        const scrollElement = HourlyBookingLightboxHelper.getScrollElement();
        switch (step) {
            case HourlyBookingLightboxStep.SelectRates:
                return {
                    top: params?.scrollToStart ? 0 : null,
                    scrollElement,
                };
            default:
                return {
                    top: 0,
                    scrollElement,
                };
        }
    };

    static getFeaturesPrice = (
        bookingFeaturesIds: string[],
        bookingFeatures: HotelFeaturesMap,
        hotelFeaturesMap: Record<string, IFeatureWithCount>
    ) => (
        bookingFeaturesIds.reduce((acc, bookingFeatureId) => {
            const count = bookingFeatures[bookingFeatureId] || 0;
            if (count > 0) {
                const price = hotelFeaturesMap[bookingFeatureId].cost;
                const finalCost = Currency.multiply(price, count);
                return Currency.add(acc, finalCost);
            }

            return acc;
        }, Currency.zero())
    );

    static getFeaturesCount = (bookingFeatures: HotelFeaturesMap) => (
        Object.keys(bookingFeatures).length
    );

    static getTotalInfo = (data: IHourlyBookingTotalInfo): IHourlyTotalPrice => {
        const {accommodationPrices, hotelFeatures, params, selectedAccommodation} = data;
        const {hourlyRateId, bookingFeatures} = selectedAccommodation;
        const {timeFrom, timeTo} = params;

        const hotelFeaturesMap = createMapBy(hotelFeatures, ho => ho.id);
        const bookingFeaturesIds = Object.keys(bookingFeatures);

        const featuresPrice = HourlyBookingLightboxHelper.getFeaturesPrice(bookingFeaturesIds, bookingFeatures, hotelFeaturesMap);
        const featuresCount = HourlyBookingLightboxHelper.getFeaturesCount(bookingFeatures);

        const totalHours = DateCalculate.getDiffByHours(timeFrom, timeTo, DateFormat.UnixMsTimestamp);
        const price = accommodationPrices.find(p => p.rateId === hourlyRateId)?.price || Currency.zero();
        const totalSum = Currency.add(price, featuresPrice);

        return {
            totalSum,
            totalHours,
            featuresCount,
        };
    };

    static getBookingsToCreate = (
        selectedAccommodation: IHourlyBookingAccommodation,
        form: IBookingOrderState,
        timeFrom: number,
        timeTo: number,
        offsetInMinutes: number,
    ): IHourlyBookingAccommodation => {
        const {comment, customer} = form;

        return {
            ...selectedAccommodation,
            customer,
            comment,
            checkin: DateHelper.convertWithTimezone(timeFrom, {
                formatIn: DateFormat.UnixMsTimestamp,
                formatOut: DateFormat.FullDateYearFirstWithTime,
                offsetInMinutes,
            }),
            checkout: DateHelper.convertWithTimezone(timeTo, {
                formatIn: DateFormat.UnixMsTimestamp,
                formatOut: DateFormat.FullDateYearFirstWithTime,
                offsetInMinutes,
            }),
        };
    };

    static getAccommodationsParams = (timeFrom: ConfigType, timeTo: ConfigType, offsetInMinutes: number): Omit<IBaseSearchParams, "adultsCount" | "kidsCount"> => (
        {
            fromDate: DateHelper.convertWithTimezone(timeFrom, {
                formatIn: DateFormat.UnixMsTimestamp,
                formatOut: DateFormat.FullDateYearFirstWithTime,
                offsetInMinutes,
            }),
            toDate: DateHelper.convertWithTimezone(timeTo, {
                formatIn: DateFormat.UnixMsTimestamp,
                formatOut: DateFormat.FullDateYearFirstWithTime,
                offsetInMinutes,
            })
        }
    );

    static getAccommodation = (accommodations: IHourlyAccommodation[], hourlyObjectId: string): IHourlyAccommodation => (
        accommodations.find(el => el.hourlyObjectId === hourlyObjectId)
    );
}
