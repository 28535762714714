import Cookies from "js-cookie";

const getCookieKey = (key: string): string => `local-storage-fallback-${key}`;

export class LocalStorageFallback {
    getItem = (key: string): string => (
        Cookies.get(getCookieKey(key)) || null
    );

    setItem = (key: string, value: string): void => {
        Cookies.set(getCookieKey(key), value);
    };

    removeItem = (key: string): void => {
        Cookies.remove(getCookieKey(key));
    };
}