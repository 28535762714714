import {IBookingContactInfo} from "../data/Booking";
import {IBookingOrderState} from "../data/BookingOrder";

export class BookingContactInfoHelper {
    static getContactInfo = (form: IBookingOrderState): IBookingContactInfo => {
        const {customer} = form;
        const {phone: customerPhone, email, fio} = customer;
        // https://yt.skbkontur.ru/issue/FMS-11051
        // Для ЮКассы нужно отправлять номер телефона в формате 79000000000
        const phone = customerPhone?.replace(/\D+/g, "") || "";

        return {
            phone,
            email,
            fio,
        };
    };
}
