import {ArrowCDownIcon16Regular} from "@skbkontur/icons/ArrowCDownIcon16Regular";
import {ArrowCDownIcon16Light} from "@skbkontur/icons/ArrowCDownIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type ArrowCurveUpIconTypes = IconTypes.Regular16 | IconTypes.Light16;

interface IArrowCurveUpIconProps extends IconProps {
    type: ArrowCurveUpIconTypes;
}

const ArrowCurveUpIcon = ({type, ...iconProps}: IArrowCurveUpIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <ArrowCDownIcon16Regular {...iconProps}/>;
        case IconTypes.Light16:
            return <ArrowCDownIcon16Light {...iconProps}/>;
    }
};
ArrowCurveUpIcon.displayName = "ArrowCurveUpIcon";
export default ArrowCurveUpIcon;
