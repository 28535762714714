import {BedType, IBedStructure, IRoomCategory, RoomCategoryHelper} from "@skbkontur/hotel-data/roomCategory";
import {useTranslation} from "@skbkontur/i18n";
import {People1Icon16Light} from "@skbkontur/icons/People1Icon16Light";
import {People3Icon16Light} from "@skbkontur/icons/People3Icon16Light";
import {People1PlusIcon16Light} from "@skbkontur/icons/People1PlusIcon16Light";
import {withRenderKey} from "@skbkontur/hotel-data/renderKey";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import {AdditionalBedType} from "@skbkontur/hotel-data/roomCategory/RoomCategory";
import styles from "../RoomCategoryLabel.scss";
import {ILabelProps, Label} from "../../../label";
import {SvgIconType, SvgIcon} from "../../../svgIcon";

interface IRoomCategoryLabelIconsProps extends ILabelProps {
    category: IRoomCategory;
    hideArea?: boolean;
    beds?: IBedStructure<BedType>[];
    additionalBeds?: IBedStructure<AdditionalBedType>[];
}

export const BED_ICONS: Record<BedType, SvgIconType> = {
    [BedType.Single]: SvgIconType.SingleBed,
    [BedType.Double]: SvgIconType.DoubleBed,
    [BedType.DoubleKing]: SvgIconType.DoubleBedKingSize,
    [BedType.DoubleSplit]: SvgIconType.DoubleSplitBed,
    [BedType.Bunk]: SvgIconType.BunkBed,
};

export const ADDITIONAL_BED_ICONS: Record<AdditionalBedType, SvgIconType> = {
    [AdditionalBedType.DoubleSofa]: SvgIconType.SofaBed,
    [AdditionalBedType.Sofa]: SvgIconType.SofaBed,
    [AdditionalBedType.Chair]: SvgIconType.SofaBed,
    [AdditionalBedType.Baby]: SvgIconType.BabyBed,
    [AdditionalBedType.Cot]: SvgIconType.Cot,
};

const RoomCategoryLabelIcons = (props: IRoomCategoryLabelIconsProps) => {
    const {
        category: {area, placesMin, placesMax, roomCategoryType, rooms, additionalPlaces},
        hideArea,
        beds = [],
        additionalBeds = [],
        small,
        textMode
    } = props;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const isHostel = RoomCategoryHelper.isHostel(roomCategoryType);
    const hostelPlaces = placesMax === placesMin ? placesMin : `${placesMin}−${placesMax}`;

    const isHasNotHiddenArea = !!area && !hideArea;
    const isHasPlaces = !!placesMin;
    const isHasAdditionalPlaces = !!additionalPlaces;

    const bedsWithKeys = beds?.map(withRenderKey) || [];
    const additionalBedsWithKeys = additionalBeds?.map(withRenderKey) || [];

    const isAnyTypeBeds = !!(bedsWithKeys.length || additionalBedsWithKeys.length);
    const isAllTypeBeds = !!(bedsWithKeys.length && additionalBedsWithKeys.length);

    if (!(isHasNotHiddenArea || isHasPlaces || rooms?.length)) {
        return null;
    }

    return (
        <Label small={small} textMode={textMode}>
            {isHasNotHiddenArea && (
                <div>
                    <span data-tid="Area">{area}</span>
                    &nbsp;
                    <span>{t("squareMetersCaption")}</span>
                </div>
            )}
            {!isHostel && isHasPlaces && (
                <div className={styles.iconBlock}>
                    <span data-tid="PlacesCount">{placesMin}</span>
                    <People1Icon16Light/>
                    {isHasAdditionalPlaces && (
                        <>
                            +
                            <span data-tid="AdditionalPlacesCount">{additionalPlaces}</span>
                            <People1PlusIcon16Light/>
                        </>
                    )}
                </div>
            )}
            {isHostel && !!hostelPlaces && (
                <div className={styles.iconBlock}>
                    <span data-tid="HostelPlacesCount" className={styles.hostelPlaces}>
                        {hostelPlaces}
                    </span>
                    <People3Icon16Light/>
                </div>
            )}
            {isAnyTypeBeds && (
                <div className={styles.iconBlock}>
                    <div className={styles.iconBlock}>
                        {bedsWithKeys.map(bed => (
                            <span key={bed.renderKey} data-tid="BedTypeIcon">
                                <SvgIcon
                                    data-tid={bed.type}
                                    type={BED_ICONS[bed.type]}
                                />
                            </span>
                        ))}
                    </div>
                    {isAllTypeBeds && "+"}
                    <div className={styles.iconBlock}>
                        {additionalBedsWithKeys.map(bed => (
                            <span key={bed.renderKey} data-tid="AdditionalBedTypeIcon">
                                <SvgIcon
                                    data-tid={bed.type}
                                    type={ADDITIONAL_BED_ICONS[bed.type]}
                                />
                            </span>
                        ))}
                    </div>
                </div>
            )}
            {isHostel && !hideArea && <div data-tid="HostelRoomText">{t("hostelRoom")}</div>}
        </Label>
    );
};
RoomCategoryLabelIcons.displayName = "RoomCategoryLabelIcons";
export default RoomCategoryLabelIcons;