import {NetConnectWifiIcon16Light} from "@skbkontur/icons/NetConnectWifiIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type WifiIconTypes = IconTypes.Light16;

interface IWifiIconProps extends IconProps {
    type: WifiIconTypes;
}

const WifiIcon = ({type, ...iconProps}: IWifiIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <NetConnectWifiIcon16Light {...iconProps}/>;
    }
};
WifiIcon.displayName = "WifiIcon";
export default WifiIcon;