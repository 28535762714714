import {Gapped, Modal} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {useDispatch, useSelector} from "react-redux";
import {useMount} from "@skbkontur/hotel-hooks/react";
import {FlippedResizer} from "@skbkontur/hotel-components/flipper";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import AccommodationsProvider from "../../Accommodations/AccommodationsProvider";
import {RoomCategorySearchParamsContext} from "../../SearchParams/SearchParamsContext";
import {DeviceContext} from "../../Device/DeviceContext";
import {WithProvider} from "../../../common/components/WithProvider/WithProvider";
import {getPaymentTerminals} from "../../../store/paymentSource/paymentSourceActionCreators";
import {getCashbackTerminalIds} from "../../../store/cashback/cashbackActionCreators";
import {IAppState} from "../../../store/AppState";
import {BookingType} from "../../../data/Booking";
import {WithErrorBoundaryLightbox} from "../../../components/ErrorBoundary/WithErrorBoundaryLightbox";
import {useLightboxResize} from "../../../hooks/useLightboxResize";
import {
    BookingLightboxStepsContext,
    isNotAvailableStep,
    isReadyToOrder,
    isSelectAccommodationsStep,
    isShowRatesStep
} from "./Steps/BookingLightboxStepsContext"; 
import BookingLightboxStepsProvider from "./Steps/BookingLightboxStepsProvider";
import BookingLightboxOrder from "./Order/BookingLightboxOrder";
import {useShouldFlipStepsChange} from "./Flipper/useShouldFlipStepsChange";
import BookingDatesNotAvailableCalendar from "./DatesNotAvailableCalendar/BookingDatesNotAvailableCalendar";
import BookingOverbookingProvider from "./Overbooking/BookingOverbookingProvider";
import {BOOKING_LIGHTBOX_CLASSNAME, BookingLightboxHelper} from "./BookingLightboxHelper";
import BookingLightboxFlipper from "./Flipper/BookingLightboxFlipper";
import BookingRates from "./Rates/BookingRates";
import BookingAccommodations from "./Accommodations/BookingAccommodations";
import styles from "./BookingLightbox.scss";
import BookingLightboxSearchForm from "./BookingLightboxSearchForm";

interface IBookingLightboxProps {
    onClose: () => void;
    onBooking: (bookingParams: BookingType) => Promise<void>;
}

const BookingLightbox = (props: IBookingLightboxProps) => {
    const {onBooking, onClose} = props;

    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const dispatch = useDispatch();

    const width = useLightboxResize(BookingLightboxHelper.getWidth);
    const {isAvailable} = useSelector((state: IAppState) => state.promoCode);
    const {isDesktopMode} = React.useContext(DeviceContext);
    const {step, params: stepParams} = React.useContext(BookingLightboxStepsContext);

    const {params: searchParams} = React.useContext(RoomCategorySearchParamsContext);
    const {fromDate, toDate} = searchParams;

    useMount(() => {
        dispatch(getPaymentTerminals());
    });

    React.useEffect(() => {
        if (fromDate && toDate) {
            dispatch(getCashbackTerminalIds(fromDate, toDate));
        }
    }, [fromDate, toDate]);

    const shouldFlip = useShouldFlipStepsChange();

    const getScrollSettings = () => (
        BookingLightboxHelper.getScrollSettings(step, stepParams, isDesktopMode, isAvailable)
    );

    return (
        <Modal
            className={BOOKING_LIGHTBOX_CLASSNAME}
            width={width}
            onClose={onClose}
            ignoreBackgroundClick
            disableFocusLock
        >
            <Modal.Header className={styles.header} sticky={isDesktopMode}>
                <div className={styles.caption}>
                    {t("BookingModal.caption")}
                </div>
            </Modal.Header>
            <Modal.Body noPadding>
                <div className={styles.container}>
                    <BookingLightboxFlipper>
                        <Gapped gap={40} vertical>
                            <BookingLightboxSearchForm/>
                            <FlippedResizer
                                flipId="Steps"
                                shouldFlip={shouldFlip}
                                getHeightSettings={() => ({withOverflow: true})}
                                getScrollSettings={getScrollSettings}
                                scrollAfterHeight={BookingLightboxHelper.isScrollWaitHeight(step)}
                            >
                                <Gapped gap={40} vertical verticalAlign="top">
                                    {isNotAvailableStep(step) && <BookingDatesNotAvailableCalendar/>}
                                    {isSelectAccommodationsStep(step) && <BookingAccommodations/>}
                                    {isShowRatesStep(step) && <BookingRates/>}
                                    {isReadyToOrder(step) && <BookingLightboxOrder onBooking={onBooking}/>}
                                </Gapped>
                            </FlippedResizer>
                        </Gapped>
                    </BookingLightboxFlipper>
                </div>
            </Modal.Body>
        </Modal>
    );
};
BookingLightbox.displayName = "BookingLightbox";
const withErrorBoundary = WithErrorBoundaryLightbox(BookingLightbox);
const withOverbookingProvider = WithProvider(withErrorBoundary, BookingOverbookingProvider);
const withStepsProvider = WithProvider(withOverbookingProvider, BookingLightboxStepsProvider);
export default WithProvider(withStepsProvider, AccommodationsProvider);
