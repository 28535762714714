import cn from "classnames";
import styles from "./Label.scss";

export interface ILabelProps {
    small?: boolean;
    textMode?: boolean;
}

const Label = (props: React.PropsWithChildren<ILabelProps>) => {
    const {textMode, small, children} = props;

    const labelStyles = cn(
        styles.label,
        {[styles.small]: small},
        {[styles.textMode]: textMode}
    );

    return (
        <div className={cn(labelStyles)}>
            {children}
        </div>
    );
};
Label.displayName = "Label";
export default Label;