import styles from "./HotelServices.scss";

interface IHotelServicesProps {
    href: string;
    text: string;
    imageSrc: string;
}

const HotelServices = (props: IHotelServicesProps) => {
    const {imageSrc, text, href} = props;

    const scrollIntoView = () => {
        if (href) {
            document.querySelector(href)?.scrollIntoView({behavior: "smooth"});
        }
    };

    return (
        <div className={styles.imageBox} onClick={scrollIntoView}>
            <div className={styles.text}>{text}</div>
            <img className={styles.image} src={imageSrc}/>
        </div>
    );
};

export default HotelServices;
