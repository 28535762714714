import {KitchenComfortType} from "@skbkontur/hotel-data/roomCategory";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import {RoomCategoryComfortsContext} from "../RoomCategoryComfortsContext";
import {RoomCategoryComfortsGroupType} from "../components/RoomCategoryComfortsGroupContext";
import RoomCategoryComfortsItem from "../components/RoomCategoryComfortsItem";
import RoomCategoryComfortsGroup from "../components/RoomCategoryComfortsGroup";
import {RollUp} from "../../../rollUp";

interface IRoomCategoryComfortsKitchenProps {
    comforts: KitchenComfortType[];
}

const RoomCategoryComfortsKitchen = (props: IRoomCategoryComfortsKitchenProps) => {
    const {comforts} = props;

    const {t} = useTranslation(TranslationNamespaces.RoomCategories);

    const {showAll} = React.useContext(RoomCategoryComfortsContext);
    const sortedComforts = Object.values(KitchenComfortType).filter(t => comforts.includes(t));

    return (
        <RoomCategoryComfortsGroup
            title={t("comforts.groups.kitchenInRoom")}
            type={RoomCategoryComfortsGroupType.Kitchen}
            withoutTitlePadding
        >
            <RollUp isShown={showAll} paddingTop={8}>
                {sortedComforts.map(comfortType => (
                    <RoomCategoryComfortsItem
                        key={comfortType}
                        groupType={RoomCategoryComfortsGroupType.Kitchen}
                        comfortType={comfortType}
                    />
                ))}
            </RollUp>
        </RoomCategoryComfortsGroup>
    );
};
RoomCategoryComfortsKitchen.displayName = "RoomCategoryComfortsKitchen";
export default RoomCategoryComfortsKitchen;
