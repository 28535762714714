import {Link} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "@skbkontur/hotel-constants/TranslationNamespaces";
import cn from "classnames";
import ArrowCurveDownIcon from "../../../icon/ArrowCurveDownIcon";
import {IconTypes} from "../../../icon";
import styles from "../RoomCategoryComforts.scss";
import {RoomCategoryComfortsContext} from "../RoomCategoryComfortsContext";

const RoomCategoryComfortsShowLink = () => {
    const {t} = useTranslation(TranslationNamespaces.RoomCategories);
    const {showAll, toggleShowAll} = React.useContext(RoomCategoryComfortsContext);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        toggleShowAll();
    };

    return (
        <Link className={styles.showLink} onClick={handleClick}>
            {showAll ? t("comforts.showLink.hide") : t("comforts.showLink.show")}
            <span className={cn(styles.showLinkIcon, {[styles.showed]: showAll})}>
                <ArrowCurveDownIcon type={IconTypes.Light16}/>
            </span>
        </Link>
    );
};
RoomCategoryComfortsShowLink.displayName = "RoomCategoryComfortsShowLink";
export default RoomCategoryComfortsShowLink;