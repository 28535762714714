import {ArrowCLeftIcon16Regular} from "@skbkontur/icons/ArrowCLeftIcon16Regular";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type ArrowCurveLeftIconTypes = IconTypes.Regular16;

interface IArrowCurveLeftIconProps extends IconProps {
    type: ArrowCurveLeftIconTypes;
}

const ArrowCurveLeftIcon = ({type, ...iconProps}: IArrowCurveLeftIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <ArrowCLeftIcon16Regular {...iconProps}/>;
    }
};
ArrowCurveLeftIcon.displayName = "ArrowCurveLeftIcon";
export default ArrowCurveLeftIcon;
