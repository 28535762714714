import {Input} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {ValidatorsFormatHelper} from "@skbkontur/hotel-validators/helpers/ValidatorsFormatHelper";
import OrderFormRow from "../Row/OrderFormRow";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {BookingOrderContext} from "../BookingOrderContext";
import {createValidationInfo} from "../../../forms/ValidationInfo";
import {IBookingOrderState} from "../../../data/BookingOrder";
import {FormFieldName, useTrackFormFieldFill} from "../../../hooks/useTrackFormFieldFill";
import {DeviceContext} from "../../Device/DeviceContext";
import styles from "./OrderFormPhone.scss";

const russianPhoneCode = "+7";

const isRussianTelephoneWithoutPlus = (phone: string) => phone.startsWith("9") || phone.startsWith("8") || phone.startsWith("7");

const OrderFormPhone = () => {
    const {t, tcn} = useTranslation(TranslationNamespaces.BookingLightbox);

    const {isMobileMode} = React.useContext(DeviceContext);

    const {
        setForm,
        form: {customer},
    } = React.useContext(BookingOrderContext);
    const {phone} = customer;

    const getValidation = () => {
        if (!phone) return createValidationInfo(tcn("enterPhone"));
        if (!ValidatorsFormatHelper.isPhoneOrEmpty(phone)) {

            return createValidationInfo(
                <div className={styles.tooltip}>
                    {tcn("validations.digitsPhone")}
                </div>
            );
        }
    };

    const handleChange = (value: string) => {
        let phone = value.replace(/[^0-9+()-]/g, "").trim();

        if (isRussianTelephoneWithoutPlus(phone)) {
            phone = russianPhoneCode + phone.replace(/^(7|8)/g, "");
        }

        setForm((value: IBookingOrderState) => ({
            ...value,
            customer: {...value.customer, phone},
        }));
    };

    const onBlur = useTrackFormFieldFill(FormFieldName.Email);

    return (
        <OrderFormRow
            caption={t("form.Other.mobilePhone")}
            validationInfo={getValidation()}
            required
        >
            <Input
                width={isMobileMode ? "100%" : 250}
                placeholder="+7900000000"
                size="medium"
                value={phone}
                onValueChange={handleChange}
                onBlur={onBlur}
            />
        </OrderFormRow>
    );
};
OrderFormPhone.displayName = "OrderFormPhone";
export default OrderFormPhone;
