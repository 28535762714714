import cn from "classnames";
import styles from "./Ellipsis.scss";

interface IEllipsisProps {
    className?: string;
    lineClamp?: number;
}

const Ellipsis = (props: React.PropsWithChildren<IEllipsisProps>) => {
    const {children, className, lineClamp = 1} = props;

    const ref = React.useRef<HTMLDivElement>();
    const [title, setTitle] = React.useState<string>();

    React.useEffect(() => {
        if (ref.current) {
            setTitle(ref.current.innerText);
        }
    });

    if (!children)
        return null;

    return (
        <div
            ref={ref}
            className={cn(styles.root, className)}
            title={title}
            style={{WebkitLineClamp: lineClamp}}
        >
            {children}
        </div>
    );
};
Ellipsis.displayName = "Ellipsis";
export default Ellipsis;
