import thunk from "redux-thunk";
import {createLogger} from "redux-logger";
import {applyMiddleware, compose, createStore} from "redux";
import {isDev} from "@skbkontur/hotel-process-env";
import mainReducer from "./mainReducer";
import asyncActionMiddleware from "./asyncActionMiddleware";

import {IAppState} from "./AppState";
import {extraArgument} from "./ExtraArgument";

export default function configureStore(initialState?: IAppState) {
    const middleware = [
        thunk.withExtraArgument(extraArgument),
        asyncActionMiddleware,
        isDev() && createLogger()
    ].filter(Boolean);

    return createStore(
        mainReducer,
        initialState,
        // @ts-expect-error Action.type error will be fixed after move PMS redux to package
        compose(applyMiddleware(...middleware))
    );
}
