import {WindowViewComfortType} from "@skbkontur/hotel-data/roomCategory";
import RoomCategoryComfortsGroup from "../components/RoomCategoryComfortsGroup";
import {RoomCategoryComfortsGroupType} from "../components/RoomCategoryComfortsGroupContext";
import RoomCategoryComfortsItem from "../components/RoomCategoryComfortsItem";

interface IRoomCategoryComfortsWindowViewProps {
    comforts: WindowViewComfortType[];
}

const RoomCategoryComfortsWindowView = (props: IRoomCategoryComfortsWindowViewProps) => {
    const {comforts} = props;

    const sortedComforts = Object.values(WindowViewComfortType).filter(t => comforts.includes(t));

    return (
        <RoomCategoryComfortsGroup type={RoomCategoryComfortsGroupType.WindowView}>
            {sortedComforts.map(comfortType => (
                <RoomCategoryComfortsItem
                    key={comfortType}
                    groupType={RoomCategoryComfortsGroupType.WindowView}
                    comfortType={comfortType}
                />
            ))}
        </RoomCategoryComfortsGroup>
    );
};
RoomCategoryComfortsWindowView.displayName = "RoomCategoryComfortsWindowView";
export default RoomCategoryComfortsWindowView;
