import {Input} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {useDispatch, useSelector} from "react-redux";
import cn from "classnames";
import {useMount} from "@skbkontur/hotel-hooks/react";
import {TranslationNamespaces} from "../../../../../constants/TranslationNamespaces";
import {IAppState} from "../../../../../store/AppState";
import {clearPromoCodeInfo, tryApplyPromoCode} from "../../../../../store/promoCode/promoCodeActionCreators";
import {RoomCategorySearchParamsContext} from "../../../../SearchParams/SearchParamsContext";
import {AccommodationsContext} from "../../../../Accommodations/AccommodationsContext";
import {SelectedAccommodationsHelper} from "../../../../Accommodations/helpers/SelectedAccommodationsHelper";
import BookingLightboxPanel from "../../Panel/BookingLightboxPanel";
import {PromoCodeResult} from "../../../../../data/PromoCode";
import {KeyboardHelper} from "../../../../../helpers/KeyboardHelper";
import {bookingLightboxPromoCodeAnalyticsEvents} from "../../../../../analytics/bookingLightboxPromoCodeAnalyticsEvents";
import PrimaryButton from "../../../../../components/PrimaryButton/PrimaryButton";
import styles from "./BookingRatesPanel.scss";

const trackPromoCodeResult: Record<PromoCodeResult, () => void> = {
    [PromoCodeResult.Accepted]: bookingLightboxPromoCodeAnalyticsEvents.trackAcceptedPromoCode,
    [PromoCodeResult.Incorrect]: bookingLightboxPromoCodeAnalyticsEvents.trackIncorrectPromoCode,
    [PromoCodeResult.NotActing]: bookingLightboxPromoCodeAnalyticsEvents.trackNotActingPromoCode
};

const BookingPromoCodePanel = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const dispatch = useDispatch();

    const {isLoading, applyResult, isAvailable} = useSelector((state: IAppState) => state.promoCode);
    const {result} = applyResult || {};
    const [promoCodeName, setPromoCodeName] = React.useState<string>();

    const {params} = React.useContext(RoomCategorySearchParamsContext);
    const {selectedAccommodationsMap} = React.useContext(AccommodationsContext);

    useMount(() => {
        bookingLightboxPromoCodeAnalyticsEvents.trackShowPromoCode();
    });

    const handleChange = (promoCodeName: string) => {
        if (applyResult) {
            dispatch(clearPromoCodeInfo());
        }
        setPromoCodeName(promoCodeName);
    };

    const handleCheck = async () => {
        bookingLightboxPromoCodeAnalyticsEvents.trackCheckPromoCode();
        const {fromDate: checkin, toDate: checkout} = params;
        const accommodationBookings = SelectedAccommodationsHelper.toSelectedAccommodationBookings(selectedAccommodationsMap);

        const bookings = accommodationBookings.map(({accommodation, params}) => {
            const {childrenCount, adultsCount, rateId, roomCategoryId} = accommodation;
            const {mainOccupancy, additionalOccupancy} = params;

            const guestCount = childrenCount ? adultsCount + childrenCount : adultsCount;
            return {checkin, checkout, rateId, guestCount, roomCategoryId, mainOccupancy, additionalOccupancy};
        });
        /* eslint-disable @typescript-eslint/await-thenable */
        // @ts-expect-error Need to use new Redux
        const {response: {result}} = await dispatch(tryApplyPromoCode({promoCodeName, bookings}));
        trackPromoCodeResult[result]?.();
    };

    const className = cn({
        [styles.acceptedStatus]: result === PromoCodeResult.Accepted,
        [styles.warningStatus]: result === PromoCodeResult.NotActing || result === PromoCodeResult.Incorrect
    });

    const preventSpecialSymbol = (e: React.KeyboardEvent<HTMLElement>) => {
        const isLatinSymbol = KeyboardHelper.isLatinSymbol(e);
        const isNumber = KeyboardHelper.isNumber(e);
        const isMinusOrUnderscore = KeyboardHelper.isMinusOrUnderscore(e);
        const isRussianSymbol = KeyboardHelper.isRussianSymbol(e);

        if (isLatinSymbol || isNumber || isMinusOrUnderscore || isRussianSymbol) {
            return;
        }
        e.preventDefault();
    };

    return (
        <BookingLightboxPanel isShown={isAvailable} isStatic={isAvailable}>
            <div className={styles.promoCodeContainer}>
                <Input
                    width={250}
                    onValueChange={handleChange}
                    size="medium"
                    placeholder={t("promoCode.placeholder", {stringFormat: true})}
                    onKeyPress={preventSpecialSymbol}
                />
                <div className={styles.promoCodeInfo}>
                    {!applyResult ? promoCodeName && (
                        <PrimaryButton
                            onClick={handleCheck}
                            loading={isLoading}
                            data-tid="CheckPromoCodeButton"
                            className={styles.promoCodeButton}
                        >
                            {t("promoCode.check")}
                        </PrimaryButton>
                    ) : (
                        <div className={className}>
                            {t(`promoCode.status.${result}`)}
                        </div>
                    )}
                </div>
            </div>
        </BookingLightboxPanel>
    );
};

BookingPromoCodePanel.displayName = "BookingPromoCodePanel";
export default BookingPromoCodePanel;
