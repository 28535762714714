import {vkMiniAppsEventAnalyticsEvents} from "./analytics/vkMiniAppsEvent";

export const initVkBridge = (organizationName: string, hotelId?: string) => {
    const script = document.createElement("script");
    script.src = "https://unpkg.com/@vkontakte/vk-bridge@2.14.1/dist/browser.min.js";
    script.async = true;
    // @ts-expect-error vkBridge types
    script.onload = () => window.vkBridge.send("VKWebAppInit").then((data) => {
        if (data.result) {
            if (hotelId) vkMiniAppsEventAnalyticsEvents.initializationWidget(organizationName, hotelId);
            else vkMiniAppsEventAnalyticsEvents.initializationMOB(organizationName);
        }
    });
    document.body.appendChild(script);
};
