import {WeatherSnowflakeIcon16Light} from "@skbkontur/icons/WeatherSnowflakeIcon16Light";
import {IconProps} from "@skbkontur/icons/internal/Icon";
import {IconTypes} from "./Icon";

type SnowIconTypes = IconTypes.Light16;

interface ISnowIconProps extends IconProps {
    type: SnowIconTypes;
}

const SnowIcon = ({type, ...iconProps}: ISnowIconProps) => {
    switch (type) {
        case IconTypes.Light16:
            return <WeatherSnowflakeIcon16Light {...iconProps}/>;
    }
};
SnowIcon.displayName = "SnowIcon";
export default SnowIcon;