import {groupBy} from "lodash";
import {memoize} from "@skbkontur/hotel-utils";
import {HotelDate, hotelDate, memoizedHotelDate} from "../hotelDate";
import {DateFormat} from "./DateFormat";
import {ConfigDateType, UnitOfTime} from "./Date";
import {DaysOfWeek, DaysOfWeekList} from "./DaysOfWeek";
import {DateCompare} from "./DateCompare";
import {IBaseArgs, ICompareArgs, IWithUnitOfTime} from "./DateInterface";
import {DateCalculate} from "./DateCalculate";

interface IStartOfArgs extends IBaseArgs {
    startOf: UnitOfTime;
}

interface IEndOfArgs extends IBaseArgs {
    endOf: UnitOfTime;
}

interface IGetDaysDifferentArgs extends ICompareArgs {
    zeroIsMinValue?: boolean;
}

interface IGetDiffFromHotelTime extends IWithUnitOfTime {
    offsetInMinutes: number;
    dayjsDate: HotelDate;
    hasStartOf?: boolean;
}

interface IGetDaysOfWeekInPeriodArgs {
    dateFrom: string;
    dateTo: string;
    format?: DateFormat;
}

interface IGetNearestDateFromIntervalArgs {
    currentDate: string;
    minDate: string;
    maxDate: string;
}

export class DateHelper {
    static getDaysCountInMonth = (date: ConfigDateType, format: DateFormat): number => hotelDate(date, format)
        .daysInMonth();

    static getWeekDay = (date: ConfigDateType, format: DateFormat): number => hotelDate(date, format).day();

    static getDayNumber = (date: ConfigDateType, format: DateFormat): number => hotelDate(date, format).date();

    static getOffsetInMinutes = () => hotelDate().utcOffset();

    static convert = (date: ConfigDateType, formatIn: DateFormat, formatOut: DateFormat): string => (
        hotelDate(date, formatIn).format(formatOut)
    );

    static convertWithTimezone = (
        date: ConfigDateType,
        options: {offsetInMinutes?: number; formatIn: DateFormat; formatOut: DateFormat}
    ): string => {
        const {offsetInMinutes, formatIn, formatOut} = options;
        const defaultOffsetInMinutes = offsetInMinutes || DateHelper.getOffsetInMinutes();

        return hotelDate(date, formatIn).utcOffset(defaultOffsetInMinutes).format(formatOut);
    };

    static getMonth = (date: ConfigDateType, format: DateFormat): number => hotelDate(date, format).month();

    static getYear = (date: ConfigDateType, format: DateFormat): number => hotelDate(date, format).year();

    static groupByWeeks = (dates: string[]): string[][] => {
        const groupObject = groupBy(dates, date => DateHelper.startOf({
            date,
            startOf: UnitOfTime.Week,
            format: DateFormat.FullDateYearFirstWithDashes
        }));

        return Object.keys(groupObject)
            .map(groupKey => DateHelper.getFirstLastDates(groupObject[groupKey]));
    };

    static groupByMonth = (dates: string[]): string[][] => {
        const groupObject = groupBy(dates, date => DateHelper.startOf({
            date,
            startOf: UnitOfTime.Month,
            format: DateFormat.FullDateYearFirstWithDashes
        }));

        return Object.keys(groupObject)
            .map(groupKey => DateHelper.getFirstLastDates(groupObject[groupKey]));
    };

    static startOf = memoize((args: IStartOfArgs): HotelDate => {
        const {date, startOf, format} = args;
        return hotelDate(date, format).startOf(startOf);
    }, {
        allowObjectArgs: true,
        getStoredArgs: (args: IStartOfArgs) => Object.values(args)
    });

    static endOf = memoize((args: IEndOfArgs): HotelDate => {
        const {date, endOf, format} = args;
        return hotelDate(date, format).endOf(endOf);
    }, {
        allowObjectArgs: true,
        getStoredArgs: (args: IEndOfArgs) => Object.values(args)
    });

    private static getFirstLastDates(dates: string[]): string[] {
        const firstDate = dates[0];
        const lastDate = dates[dates.length - 1];

        return [firstDate, lastDate];
    }

    static getDiffFromHotelTime = (args: IGetDiffFromHotelTime): number => {
        const {
            dayjsDate,
            offsetInMinutes,
            unitOfTime = UnitOfTime.Day,
            hasStartOf
        } = args;

        const dayjsDateUtcOffset = dayjsDate.utcOffset();
        let date = dayjsDateUtcOffset !== offsetInMinutes
            ? dayjsDate.add(dayjsDateUtcOffset - offsetInMinutes, UnitOfTime.Minute).utcOffset(offsetInMinutes)
            : dayjsDate;
        let currentHotelDayjs = DateCalculate.getCurrentHotelDayjs(offsetInMinutes);

        if (hasStartOf) {
            date = DateHelper.startOf({
                date,
                startOf: unitOfTime,
                format: DateFormat.FullDateDayFirst
            });

            currentHotelDayjs = DateHelper.startOf({
                date: currentHotelDayjs,
                startOf: unitOfTime,
                format: DateFormat.FullDateDayFirst
            });
        }

        return currentHotelDayjs.diff(date, unitOfTime);
    };

    static getDaysArrayFromMonthNumber = (month: number): number[] => {
        if (!Number.isInteger(month) || month < 1 || month > 12) return [];

        const daysInMonth = hotelDate().month(month - 1).daysInMonth();
        const result = [];

        for (let i = 1; i <= daysInMonth; i++) {
            result.push(i);
        }

        return result;
    };

    static getDatesDifference = (args: IGetDaysDifferentArgs): number => {
        const {
            firstDate,
            secondDate,
            format,
            unitOfTime = UnitOfTime.Day,
            zeroIsMinValue = true
        } = args;

        const difference = DateHelper.startOf({date: secondDate, startOf: unitOfTime, format})
            .diff(DateHelper.startOf({date: firstDate, startOf: unitOfTime, format}), unitOfTime);

        return zeroIsMinValue ? Math.max(difference, 0) : difference;
    };

    static getHoursFromDate = memoize((args: IBaseArgs): number => {
        const {format, date} = args;
        const stringHours = memoizedHotelDate(date, format).format(DateFormat.OnlyHours);
        return parseInt(stringHours, 10);
    }, {
        getStoredArgs: (args: IBaseArgs) => Object.values(args)
    });

    static getMinutesFromDate = memoize((args: IBaseArgs): string => {
        const {format, date} = args;
        const stringMinutes = memoizedHotelDate(date, format).format(DateFormat.OnlyMinutes);
        return stringMinutes.padStart(2, "0");
    }, {
        getStoredArgs: (args: IBaseArgs) => Object.values(args)
    });

    static getDaysOfWeekInPeriod = (args: IGetDaysOfWeekInPeriodArgs): DaysOfWeek[] => {
        const {dateFrom, dateTo, format = DateFormat.FullDateYearFirstWithDashes} = args;

        if (!dateFrom || !dateTo) {
            return [];
        }

        const daysCount = DateHelper.getDatesDifference({
            firstDate: dateFrom,
            secondDate: dateTo,
            zeroIsMinValue: false,
            format
        }) + 1;

        if (daysCount <= 0) {
            return [];
        }

        const fromDayOfWeek = memoizedHotelDate(dateFrom, DateFormat.FullDateYearFirstWithDashes).isoWeekday() - 1;
        const daysOfWeek: number[] = [];

        for (let i = 0; i < daysCount; i++) {
            const dayOfWeek = (fromDayOfWeek + i) % 7;
            if (!daysOfWeek.includes(dayOfWeek)) {
                daysOfWeek.push(dayOfWeek);
            }
        }

        return daysOfWeek.map(day => DaysOfWeekList[day]);
    };

    static getNearestDateFromInterval = memoize((args: IGetNearestDateFromIntervalArgs): string => {
        const {minDate, maxDate, currentDate} = args;
        return (
            minDate
            && DateCompare.isFirstSameOrBeforeSecond(currentDate, minDate, DateFormat.FullDateYearFirstWithTime)
                ? minDate
                : maxDate && (
                    DateCompare.isFirstSameOrAfterSecond(currentDate, maxDate, DateFormat.FullDateYearFirstWithTime)
                )
                    ? maxDate
                    : currentDate
        );
    }, {
        getStoredArgs: (args: IGetNearestDateFromIntervalArgs) => Object.values(args)
    });

    static getDayJs = (
        value: string,
        format: DateFormat = DateFormat.FullDateYearFirstWithDashes,
        strict: boolean = true
    ) => (
        hotelDate(value, format, strict)
    );

    static getLocalTimezoneOffset = () => (
        new Date().getTimezoneOffset() * -1
    );

    /**
     * @return 9 сентября, 1 − 9 сентября, 1 января − 9 сентября
     */
    static getFormattedPeriodWithoutYear = (
        startDate: string,
        endDate: string,
        format: DateFormat = DateFormat.FullDateYearFirstWithDashes
    ): string => {
        const isCorrectPeriod = DateCompare.isBefore({
            firstDate: startDate,
            secondDate: endDate,
            format,
            unitOfTime: UnitOfTime.Day
        });

        if (!isCorrectPeriod) {
            return "";
        }

        const isSameDay = DateCompare.isSame({
            firstDate: startDate,
            secondDate: endDate,
            format,
            unitOfTime: UnitOfTime.Day
        });

        if (isSameDay) {
            return hotelDate(startDate, format).format(DateFormat.DayWithTextMonth);
        }

        const continuousHyphen = "\u2013";
        const nonBreakingSpace = "\u00A0";
        const hyphen = `${nonBreakingSpace}${continuousHyphen}${nonBreakingSpace}`;

        const isSameMonth = DateCompare.isSame({
            firstDate: startDate,
            secondDate: endDate,
            format,
            unitOfTime: UnitOfTime.Month
        });

        if (isSameMonth) {
            const startDay = hotelDate(startDate, format).format(DateFormat.OnlyDay);
            const endDayWithMonth = hotelDate(endDate, format).format(DateFormat.DayWithTextMonth);

            return `${startDay}${hyphen}${endDayWithMonth}`;
        }

        const startDayWithMonth = hotelDate(startDate, format).format(DateFormat.DayWithTextMonth);
        const endDayWithMonth = hotelDate(endDate, format).format(DateFormat.DayWithTextMonth);

        return `${startDayWithMonth}${hyphen}${endDayWithMonth}`;
    };
}

