import {BathroomComfortType, BathroomType} from "@skbkontur/hotel-data/roomCategory";
import RoomCategoryBathroomType from "../../label/components/RoomCategoryBathroomType";
import {RoomCategoryComfortsGroupType} from "../components/RoomCategoryComfortsGroupContext";
import RoomCategoryComfortsItem from "../components/RoomCategoryComfortsItem";
import RoomCategoryComfortsGroup from "../components/RoomCategoryComfortsGroup";
import {RoomCategoryComfortsContext} from "../RoomCategoryComfortsContext";
import {RollUp} from "../../../rollUp";

interface IRoomCategoryComfortsBathroomProps {
    bathroomType: BathroomType;
    comforts: BathroomComfortType[];
}

const RoomCategoryComfortsBathroom = (props: IRoomCategoryComfortsBathroomProps) => {
    const {bathroomType, comforts} = props;

    const {showAll} = React.useContext(RoomCategoryComfortsContext);

    const sortedComforts = Object.values(BathroomComfortType).filter(t => comforts.includes(t));

    return (
        <RoomCategoryComfortsGroup
            title={bathroomType ? <RoomCategoryBathroomType bathroomType={bathroomType}/> : null}
            type={RoomCategoryComfortsGroupType.Bathroom}
            withoutTitlePadding
        >
            <RollUp isShown={showAll} paddingTop={8}>
                {sortedComforts.map(comfortType => (
                    <RoomCategoryComfortsItem
                        key={comfortType}
                        groupType={RoomCategoryComfortsGroupType.Bathroom}
                        comfortType={comfortType}
                    />
                ))}
            </RollUp>
        </RoomCategoryComfortsGroup>
    );
};
RoomCategoryComfortsBathroom.displayName = "RoomCategoryComfortsBathroom";
export default RoomCategoryComfortsBathroom;
