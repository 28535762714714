import {Gapped} from "@skbkontur/react-ui";
import {useDispatch} from "react-redux";
import {isEqual} from "lodash";
import {useMount} from "@skbkontur/hotel-hooks/react";
import {FlippedResizer} from "@skbkontur/hotel-components/flipper";
import {HourlyObjectsSearchParamsContext} from "../../SearchParams/SearchParamsContext";
import {WithProvider} from "../../../common/components/WithProvider/WithProvider";
import {WithErrorBoundaryLightbox} from "../../../components/ErrorBoundary/WithErrorBoundaryLightbox";
import HourlyObjectsListAvailableTime from "../../../components/HourlyObjectsList/HourlyObjectsListAvailableTime";
import HourlyAccommodationsProvider from "../../HourlyAccommodations/HourlyAccommodationsProvider";
import {HourlyAccommodationsContext} from "../../HourlyAccommodations/HourlyAccommodationsContext";
import {HourlyBookingType} from "../../../data/HourlyBooking";
import {getPaymentTerminals} from "../../../store/paymentSource/paymentSourceActionCreators";
import {IHourlyObjectSearchParams} from "../../../data/SearchParams";
import {clearAvailabilitiesHourly} from "../../../store/availabilitiesHourly/availabilitiesHourlyActionCreators";
import styles from "./HourlyBookingLightbox.scss";
import HourlyBookingLightboxStepsProvider from "./Steps/HourlyBookingLightboxStepsProvider";
import {
    HourlyBookingLightboxStep,
    HourlyBookingLightboxStepsContext,
    isReadyToOrder,
    isShowRatesStep,
    isShowSearch,
} from "./Steps/HourlyBookingLightboxStepsContext";
import HourlyBookingRates from "./Rates/HourlyBookingRates";
import HourlyBookingLightboxFlipper from "./Flipper/HourlyBookingLightboxFlipper";
import {HourlyBookingLightboxHelper} from "./HourlyBookingLightboxHelper";
import HourlyBookingLightboxModal from "./HourlyBookingLightboxModal";
import {useHourlyShouldFlipStepsChange} from "./Flipper/useHourlyShouldFlipStepsChange";
import HourlyBookingLightboxOrder from "./Order/HourlyBookingLightboxOrder";
import HourlyBookingLightboxCaption from "./Caption/HourlyBookingLightboxCaption";
import HourlyBookingOverbookingProvider from "./Overbooking/HourlyBookingOverbookingProvider";

interface IHHourlyBookingLightboxProps {
    onClose: () => void;
    onBooking: (bookingParams: HourlyBookingType) => Promise<void>;
}

const HourlyBookingLightbox = (props: IHHourlyBookingLightboxProps) => {
    const {onBooking, onClose} = props;

    const dispatch = useDispatch();
    const {updateAccommodation} = React.useContext(HourlyAccommodationsContext);
    const {params} = React.useContext(HourlyObjectsSearchParamsContext);
    const {step, params: stepParams, goToStep} = React.useContext(HourlyBookingLightboxStepsContext);
    const {hourlyObjectId, hourlyObjectItemId} = params;

    React.useEffect(() => {
        updateAccommodation({hourlyObjectId, hourlyObjectItemId});
    }, [hourlyObjectId, hourlyObjectItemId]);

    useMount(() => {
        dispatch(getPaymentTerminals());

        return () => {
            dispatch(clearAvailabilitiesHourly());
        };
    });

    const shouldFlip = useHourlyShouldFlipStepsChange();
    const isStepToMakeOrder = isReadyToOrder(step);

    const changeParams = (changedParams: IHourlyObjectSearchParams) => {
        const isParamsChanged = !isEqual(params, changedParams);
        const {timeFrom, timeTo} = changedParams;
        const noTimeChosen = !timeFrom || !timeTo;

        if ((isParamsChanged && step !== HourlyBookingLightboxStep.Initial) || noTimeChosen) {
            goToStep(HourlyBookingLightboxStep.Initial);
        } else if (!isParamsChanged && step === HourlyBookingLightboxStep.Initial) {
            goToStep(HourlyBookingLightboxStep.SelectRates);
        }
    };

    return (
        <HourlyBookingLightboxModal
            caption={<HourlyBookingLightboxCaption/>}
            width={isStepToMakeOrder && 650}
            onClose={onClose}
        >
            <div className={styles.container}>
                <HourlyBookingLightboxFlipper>
                    <FlippedResizer
                        flipId="Steps"
                        shouldFlip={shouldFlip}
                        getHeightSettings={() => ({withOverflow: true})}
                        getScrollSettings={() => HourlyBookingLightboxHelper.getScrollSettings(step, stepParams)}
                        scrollAfterHeight={HourlyBookingLightboxHelper.isScrollWaitHeight(step)}
                    >
                        {isShowSearch(step) && (
                            <Gapped gap={40} vertical>
                                <HourlyObjectsListAvailableTime
                                    inline
                                    hourlyObjectId={hourlyObjectId}
                                    hourlyObjectItemId={hourlyObjectItemId}
                                    onChange={changeParams}
                                />
                                {isShowRatesStep(step) && <HourlyBookingRates/>}
                            </Gapped>
                        )}
                        {isStepToMakeOrder && <HourlyBookingLightboxOrder onBooking={onBooking}/>}
                    </FlippedResizer>
                </HourlyBookingLightboxFlipper>
            </div>
        </HourlyBookingLightboxModal>
    );
};
HourlyBookingLightbox.displayName = "HourlyBookingLightbox";
const withErrorBoundary = WithErrorBoundaryLightbox(HourlyBookingLightbox);
const withOverbookingProvider = WithProvider(withErrorBoundary, HourlyBookingOverbookingProvider);
const withStepsProvider = WithProvider(withOverbookingProvider, HourlyBookingLightboxStepsProvider);

export default WithProvider(withStepsProvider, HourlyAccommodationsProvider);
